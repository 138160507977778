import React from 'react';
import { Box, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useSeason } from '../seasons/seasons-context';
import { withWinemaker } from './observables';


export function WinemakersNameInList({ winemaker, buttonProps = {}, ...props }) {

    const season = useSeason();

    return (
        <ListItem {...props}>
            <ListItemButton  color='secondary' {...buttonProps}>
                <ListItemText primary={
                    <Box>
                        <Typography fontSize={14}>{winemaker.name}</Typography>
                        <Typography fontSize={14} variant='subtitle1'>{winemaker.description}</Typography>
                    </Box>
                }
                    secondary={season ? `Season ${season.name}` : undefined} />
            </ListItemButton>
        </ListItem>
    )
}

export default withWinemaker(WinemakersNameInList);
