import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteTank } from './hooks';
import TankCard from './tank-card';
import { useTranslation } from 'react-i18next';

export function TankDeleteModal({ tank, open, onClose }) {

    const { t } = useTranslation(['translation', 'tanks']);
    const deleteTank = useDeleteTank();
    const handleClick = async (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        await deleteTank(tank);
        onClose();
    }

    const [blends, setBlends] = useState(null);

    useEffect(() => {
        tank.blends.fetch().then(blends => {
            setBlends(blends);
        });
        // eslint-disable-next-line
    }, []);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {t('Delete the tank ?', { ns: 'tanks' })}
            </DialogTitle>
            <DialogContent>
                <TankCard tank={tank} hideViewAction hideRemoveAction />
                {blends !== null && blends.length > 0 && (
                    <Alert sx={{ mt: 2 }} severity="warning">{t('Tank is used in blends', { ns: 'tanks' })}</Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button color='inherit' onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button disabled={blends === null || blends.length > 0} color='primary' onClick={handleClick}>
                    {t('Confirm')}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default function TankDeleteButton({ tank }) {

    const [open, setOpen] = useState(false);

    const handleClickOpen = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(true);
    };

    const handleClose = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(false);
    };

    return (
        <Box sx={{ p: 2 }}>
            <IconButton onClick={handleClickOpen}>
                <DeleteIcon />
            </IconButton>
            <TankDeleteModal open={open} onClose={handleClose} tank={tank} />
        </Box>
    )
}
