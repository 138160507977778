import React, { Fragment, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardActions, CardContent, Grid, InputAdornment, Stack, Table, TableCell, TableRow, Typography } from '@mui/material';
import BlendDeleteButton from './blend-delete-button';
import { RemainingBlendVolumeGraph, RemainingBlendVolumeMessage } from './remaining-blend-volume';
import { useTranslation } from 'react-i18next';
import BlendSugarConcentration from './blend-sugar-concentration';
import { useUpdateBlendVolume } from './hooks';
import EditableTypo from '../misc/EditableTypo';

export default function BlendCard({ blend, onClick = (id) => { }, enableEditors = false,
    showId = false, hideName = false, hideDescription = false, hideVolume = false,
    hideRemainingVolume = false, hideSugar = false,
    hideViewAction = false, hideRemoveAction = false }) {

    const { id, name, description, volume } = blend;
    const [inputVolume, setVolume] = useState(volume);
    const update = useUpdateBlendVolume();

    const { t } = useTranslation(['translation', 'blends']);

    const handleClick = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        onClick(id);
    }

    return (
        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} onClick={handleClick}>
            <CardContent sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={!hideSugar ? 6 : undefined} md={!hideSugar ? 6 : undefined}>
                        <Table size='small'>
                            {!hideName && <TableRow>
                                <TableCell colSpan={2} align='center'><Typography >{name}</Typography></TableCell>
                            </TableRow>}
                            {showId && <TableRow>
                                <TableCell><Typography fontSize={10}>ID</Typography></TableCell>
                                <TableCell><Typography fontSize={10}>{id}</Typography></TableCell>
                            </TableRow>}
                            {!hideDescription && <TableRow>
                                <TableCell><Typography fontSize={12}>{t('Description', { ns: 'blends' })}</Typography></TableCell>
                                <TableCell><Typography fontSize={12}>{description || '—'}</Typography></TableCell>
                            </TableRow>}
                            {!hideVolume && <TableRow>
                                <TableCell><Typography fontSize={12}>{t('Volume', { ns: 'blends' })}</Typography></TableCell>
                                <TableCell>
                                    <EditableTypo enableEditor={enableEditors} textfield={{
                                        type: 'number', value: inputVolume,
                                        fontSize: 12,
                                        onChange: (ev) => {
                                            const volume = parseFloat(ev.target.value);
                                            setVolume(volume);
                                        },
                                        onBlur: (ev) => {
                                            update(blend, inputVolume);
                                        },
                                        InputProps: {
                                            endAdornment: <InputAdornment position="end">{t('hL')}</InputAdornment>
                                        }
                                    }} typography={{
                                        fontSize: 12
                                    }} >{inputVolume} {t('hL')}</EditableTypo>
                                </TableCell>
                            </TableRow>}
                            {!hideRemainingVolume && (
                                <Fragment>
                                    <TableRow>
                                        <TableCell><Typography fontSize={12}>{t('Remaining Volume', { ns: 'blends' })}</Typography></TableCell>
                                        <TableCell>
                                            <Stack direction='row' alignItems='center' spacing={2}>
                                                <RemainingBlendVolumeMessage fontSize={12} blend={blend} />
                                                <RemainingBlendVolumeGraph flexGrow={1} blend={blend} />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                </Fragment>
                            )}
                        </Table>
                    </Grid>
                    {!hideSugar && (
                        <Grid item xs={12} sm={6} md={6}>
                            <Accordion defaultExpanded>
                                <AccordionSummary >{t('Sugar', { ns: 'blends' })}</AccordionSummary>
                                <AccordionDetails><BlendSugarConcentration blend={blend} enableEditors={enableEditors} /></AccordionDetails>
                            </Accordion>
                        </Grid>
                    )}
                </Grid>

            </CardContent>
            <CardActions>
                <Stack sx={{ width: '100%' }} direction='row' justifyContent='center'>
                    {!hideViewAction && <Button size="small" onClick={handleClick} >{t('View')}</Button>}
                    {!hideRemoveAction && <BlendDeleteButton blend={blend} />}
                </Stack>
            </CardActions>
        </Card >
    )
}
