import React, { createContext, useContext, } from 'react';
import { withContext } from '../misc/withContext';
import { withBlendsPeriodInSeason } from '../seasons/observables';
import BlendsPeriodCreate from './blends-period-create';

const BlendsPeriodContext = createContext({
    blendsPeriod: null
});

export const useBlendsPeriod = () => useContext(BlendsPeriodContext).blendsPeriod;
export const withBlendsPeriodInCtx = withContext(BlendsPeriodContext, ({ blendsPeriod }) => ({
    blendsPeriod,
}));

function BlendsPeriodProviderInternal({ season, blendsPeriod, children }) {

    if (blendsPeriod === null) {
        return <BlendsPeriodCreate season={season} />
    }

    return (
        <BlendsPeriodContext.Provider value={{ blendsPeriod }}>
            {children}
        </BlendsPeriodContext.Provider>
    )

}

const BlendsPeriodProvider = withBlendsPeriodInSeason(BlendsPeriodProviderInternal);

export default BlendsPeriodProvider;
