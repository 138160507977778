import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBarMUI from '@mui/material/AppBar';
import { Typography, Breadcrumbs as BreadcrumbsMUI, Toolbar, Stack, useTheme, Link, Box, Grid } from '@mui/material';
import _ from 'lodash';

export function Breadcrumbs({ breadcrumbs }) {

    return (
        <Box role="presentation">
            <BreadcrumbsMUI aria-label="breadcrumb">
                {_.map(breadcrumbs, ({ href, text, color = 'inherit', underline = 'hover', current = false }) => (
                    <Link
                        underline={underline}
                        color={color}
                        href={href}
                        aria-current={current ? "page" : undefined}
                    >
                        {text}
                    </Link>
                ))}
            </BreadcrumbsMUI>
        </Box>
    )
}

export default function AppBar({ children, padding = 4, sidebar = null, breadcrumbs = null }) {

    const navigate = useNavigate();
    const theme = useTheme();

    const handleClick = (location) => {
        navigate('/');
    }

    return (
        <Fragment>
            <AppBarMUI sx={{ zIndex: theme.zIndex.drawer + 1, }} position="fixed">
                <Toolbar>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} onClick={handleClick}>
                        Œnology Baby
                    </Typography>
                </Toolbar>
            </AppBarMUI>
            <Stack flexGrow={1} flexDirection='row'>
                {sidebar}
                <Stack flexGrow={1} flexDirection='column'>
                    <Box sx={{ ...theme.mixins.toolbar }} ></Box>
                    <Grid sx={{ p: padding }} flexGrow={1} container spacing={2}>
                        {breadcrumbs && (
                            <Grid item xs={12}>
                                <Breadcrumbs breadcrumbs={breadcrumbs} />
                            </Grid>
                        )}
                        <Grid sx={{ display: 'flex' }} item xs={12} flexGrow={1}>
                            {children}
                        </Grid>
                    </Grid>
                </Stack>
            </Stack>
        </Fragment>

    );
}