import React from 'react';
import { Button, Box, Stack, Typography } from '@mui/material';
import { Formik } from 'formik';
import { useCreatePullingsPeriod } from './hooks';
import { useTranslation } from 'react-i18next';

export default function PullingsPeriodCreate({ season }) {

    const { t } = useTranslation(['translation', 'pulling']);
    const createPullingsPeriod = useCreatePullingsPeriod();


    return (
        <Box flexGrow={1} display='flex' alignItems='center' justifyContent='center'>
            <Formik
                initialValues={{}}
                onSubmit={async (pullingsPeriod, { setSubmitting }) => {
                    await createPullingsPeriod(season);
                    setSubmitting(false);
                }}
            >
                {props => (
                    <Stack sx={{ width: '500px' }} spacing={2} alignItems='center'>
                        <Typography variant='h4'>
                            {t('Start Pulling', { ns: 'pulling' })}
                        </Typography>
                        <Button fullWidth color='secondary' variant='outlined' onClick={props.submitForm}>
                            {t('Start')}
                        </Button>
                    </Stack>
                )}
            </Formik>
        </Box >
    )
}
