import _ from "lodash";
import { GROUP_CHAMPAGNE } from "./Varieties";

export const CUVEE = { id: 'cuvee', name: "Vintage", groups: [GROUP_CHAMPAGNE] };
export const COEURCUVEE = { id: 'coeur_de_cuvee', name: "Heart of vintage", groups: [GROUP_CHAMPAGNE] };
export const TAILLE = { id: 'taille', name: "Cut", groups: [GROUP_CHAMPAGNE] };

export const FRACTIONS = [CUVEE, TAILLE, COEURCUVEE];

export const getFractionById = (id) => _.find(FRACTIONS, (f) => f.id === id);
export const champagneFractions = () => _.filter(FRACTIONS, (f) => f.groups.includes(GROUP_CHAMPAGNE));
export const fractionsFor = (group) => _.filter(FRACTIONS, (f) => f.groups.includes(group));
