import { useDatabase } from '../../models/underground/database';
import { useCallback } from 'react';
import { createTank, deleteTank, findTank } from '../../models/Tanks';

export const useFindTank = () => {
    const database = useDatabase();
    return useCallback(async (id) => {
        return await findTank(database, id);
    }, [database]);
}

export const useCreateTank = () => {
    const database = useDatabase();

    return useCallback(async (tank) => {
        await createTank(database, tank);
    }, [database]);
}

export const useDeleteTank = () => {
    const database = useDatabase();

    return useCallback(async (tank) => {
        await deleteTank(database, tank);
    }, [database]);
}
