import { tableSchema, Model, Q } from '@nozbe/watermelondb'
import { children, date, field, lazy, relation, text } from '@nozbe/watermelondb/decorators'
import { tanksInBlendTableName } from './TanksInBlends';
import { winemakersTableName } from './Winemaker';

export const tanksTableName = 'tanks';

export const tanksSchema = tableSchema({
    name: tanksTableName,
    columns: [
        { name: 'name', type: 'string' }, // nom donné par le récoltant.
        { name: 'description', type: 'string', isOptional: true }, // informations supplémentaires optionnelles.
        { name: 'varieties', type: 'string' }, // parfois les cuves sont des assemblages
        { name: 'fraction', type: 'string' },
        { name: 'year', type: 'number' },
        { name: 'volume', type: 'number' }, // volume en hectolitres.
        { name: 'winemaker_id', type: 'string' },
        { name: 'blends_period_id', type: 'string' },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
    ]
});

export default class Tank extends Model {
    static table = tanksTableName
    static associations = {
        [tanksInBlendTableName]: { type: 'has_many', foreignKey: 'tank_id' },
        blendsperiod: { type: 'belongs_to', key: 'blends_period_id' },
    }

    @text('name') name
    @text('description') description
    @text('varieties') varieties
    @text('fraction') fraction
    @field('year') year
    @field('volume') volume
    @relation(winemakersTableName, 'winemaker_id') winemaker
    @relation('blendsperiod', 'blends_period_id') blendsPeriod
    @children(tanksInBlendTableName) tanksInBlend

    @lazy blends = this.collections
        .get('blends')
        .query(Q.on(tanksInBlendTableName, 'tank_id', this.id));

    @date('created_at') createdAt
    @date('updated_at') updatedAt
}

export async function findTank(database, id) {
    const tank = await database.get(tanksTableName).find(id);
    return tank;
}

export async function createTank(database, tank) {
    await database.write(async () => {
        const newTank = await database.get(tanksTableName).create(t => {
            t.name = tank.name;
            t.description = tank.description;
            t.varieties = tank.varieties;
            t.fraction = tank.fraction;
            t.year = tank.year;
            t.volume = tank.volume;
            t.winemaker.set(tank.winemaker);
            t.blendsPeriod.set(tank.blendsPeriod);
        })
        return newTank;
    });
}

export async function deleteTank(database, tank) {
    await database.write(async () => {
        await tank.markAsDeleted();
    });
}
