import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import moment from 'moment';
import { useCreateBlendsPeriod, useEndBlendsPeriod } from './hooks';
import { withBlendsPeriodInSeason } from '../seasons/observables';
import SeasonTimelineItem from '../seasons/season-timeline-item';

function BlendsPeriodInTimelineInternal({ season, blendsPeriod }) {

    const { t } = useTranslation(['translation', 'seasons']);

    const createBlendsPeriod = useCreateBlendsPeriod();
    const endBlendsPeriod = useEndBlendsPeriod();

    const handleClickStart = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        createBlendsPeriod(season).catch(e => console.error(e));
    }

    const handleClickFinish = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        endBlendsPeriod(blendsPeriod).catch(e => console.error(e));
    }

    const status = blendsPeriod ? blendsPeriod.endedAt ? 'done' : 'running' : 'idle';
    const date = blendsPeriod ? moment(blendsPeriod.endedAt || blendsPeriod.createdAt).format('MM/YYYY') : 'Awaiting';
    const title = t('Blends');

    return (
        <SeasonTimelineItem status={status} date={date} title={title}>
            <Fragment>
                {!blendsPeriod && (
                    <Button variant='outlined' color='secondary' onClick={handleClickStart}>{t('Start')}</Button>
                )}
                {blendsPeriod && !blendsPeriod.endedAt && (
                    <Button variant='outlined' color='secondary' onClick={handleClickFinish}>{t('Finished')}</Button>
                )}
            </Fragment>
        </SeasonTimelineItem>
    )
}

const BlendsPeriodInTimeline = withBlendsPeriodInSeason(BlendsPeriodInTimelineInternal);

export default BlendsPeriodInTimeline