import { tableSchema, Model } from '@nozbe/watermelondb'
import { date, relation, text } from '@nozbe/watermelondb/decorators'
import { blendsPeriodTableName } from './BlendPeriod';
import { harvestPeriodTableName } from './HarvestPeriod';
import { pullingsPeriodTableName } from './PullingsPeriod';

export const seasonTableName = 'seasons';

export const seasonsSchema = tableSchema({
    name: seasonTableName,
    columns: [
        { name: 'name', type: 'string' },
        { name: 'harvest_period_id', type: 'string', isOptional: true },
        { name: 'blends_period_id', type: 'string', isOptional: true },
        { name: 'pullings_period_id', type: 'string', isOptional: true },
        { name: 'winemaker_id', type: 'string' },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
    ]
});

export default class Season extends Model {
    static table = seasonTableName
    static associations = {
        [harvestPeriodTableName]: { type: 'belongs_to', key: 'harvest_period_id' },
        [blendsPeriodTableName]: { type: 'belongs_to', key: 'blends_period_id' },
        [pullingsPeriodTableName]: { type: 'belongs_to', key: 'pullings_period_id' },
        winemakers: { type: 'belongs_to', key: 'winemaker_id' },
    }

    @text('name') name

    @relation(harvestPeriodTableName, 'harvest_period_id') harvestPeriod
    @relation(blendsPeriodTableName, 'blends_period_id') blendsPeriod
    @relation(pullingsPeriodTableName, 'pullings_period_id') pullingsPeriod
    @relation('winemakers', 'winemaker_id') winemaker

    @date('created_at') createdAt
    @date('updated_at') updatedAt
}

export async function createSeason(database, season) {
    await database.write(async () => {
        const newSeason = await database.get(seasonTableName).create(s => {
            s.name = season.name;
            s.harvest_period_id = null;
            s.blends_period_id = null;
            s.pullings_period_id = null;
            s.winemaker.set(season.winemaker);
        });
        return newSeason;
    });
}