import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { GROUP_CHAMPAGNE, varietiesFor } from '../../models/Varieties';
import { Stack, Typography } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import VarietiesToChip from './varieties-to-chip';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function VarietiesMultipleSelect({ value = null, onChange = (ev) => { }, group = GROUP_CHAMPAGNE, label, fullWidth = false, id, name}) {

    const VARIETIES = varietiesFor(group);
    const { t } = useTranslation('varieties');

    const handleChange = (event) => {
        let { target: { value } } = event;
        // On autofill we get a stringified value.
        value = typeof value === 'string' ? value.split(',') : value;
        event.target.value = value.join(',');
        onChange(event);
    };


    return (
        <FormControl fullWidth={fullWidth}>
            <InputLabel id="varieties-multiple-chip-label">{label}</InputLabel>
            <Select
                labelId="varieties-multiple-chip-label"
                id="varieties-multiple-chip"
                multiple
                value={value ? value.split(',') : []}
                onChange={handleChange}
                input={<OutlinedInput id={id} label={label} name={name} />}
                renderValue={(selected) => <VarietiesToChip varieties={selected.join(',')} group={GROUP_CHAMPAGNE} />}
                MenuProps={MenuProps}
            >
                {VARIETIES.map((variety) => (
                    <MenuItem
                        key={variety.id}
                        value={variety.id}
                    >
                        <Stack direction='row' spacing={2} alignItems='center' justifyContent='flex-start'>
                            <CircleIcon sx={{ fill: variety.color }} />
                            <Typography>{t(variety.name)}</Typography>
                        </Stack>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
