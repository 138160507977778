import { tableSchema, Model } from '@nozbe/watermelondb'
import { children, date, relation } from '@nozbe/watermelondb/decorators'
import moment from 'moment';

export const blendsPeriodTableName = 'blendsperiod';

export const blendsPeriodSchema = tableSchema({
    name: blendsPeriodTableName,
    columns: [
        { name: 'season_id', type: 'string' },
        { name: 'ended_at', type: 'number', isOptional: true },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
    ]
});

export default class BlendsPeriod extends Model {
    static table = blendsPeriodTableName
    static associations = {
        seasons: { type: 'belongs_to', key: 'season_id' },
        blends: { type: 'has_many', foreignKey: 'blends_period_id' },
        tanks: { type: 'has_many', foreignKey: 'blends_period_id' },
    }

    @relation('seasons', 'season_id') season
    @date('ended_at') endedAt
    @children('blends') blends
    @children('tanks') tanks

    @date('created_at') createdAt
    @date('updated_at') updatedAt
}

export async function createBlendsPeriod(database, season) {
    await database.write(async () => {
        const newBlendsPeriod = await database
            .get(blendsPeriodTableName)
            .create(p => {
                p.season.set(season);
            });
        await season.update(() => {
            season.blendsPeriod.set(newBlendsPeriod);
        });
        return newBlendsPeriod;
    });
}

export async function endBlendsPeriod(database, blendsPeriod) {
    await database.write(async () => {
        await blendsPeriod.update((p) => {
            p.endedAt = moment().valueOf();
        });
    });
}
