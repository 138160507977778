import React from 'react';
import { Button, Card, CardActions, CardContent, Stack, Table, TableCell, TableRow, Typography } from '@mui/material';
import TankDeleteButton from './tank-delete-button';
import TankRemainingVolumeGraph, { TankRemainingVolumeValue } from './tank-remaining-volume-graph';
import VarietiesToChip from '../varieties/varieties-to-chip';
import { useTranslation } from 'react-i18next';
import FractionName from '../fractions/fraction-name';

export default function TankCard({ tank, onClick = (id) => { }, enableEditors = false,
    showId = false, hideName = false, hideDescription = false, hideVarieties = false, hideFraction = false,
    hideYear = false, hideVolume = false, hideRemainingVolume = false,
    hideViewAction = false, hideRemoveAction = false, ...props }) {

    const { id, name, description, varieties, fraction, year, volume } = tank;
    const { t } = useTranslation(['translation', 'tanks']);
    const handleClick = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        onClick(id);
    }

    return (
        <Card {...props} sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} onClick={handleClick}>
            <CardContent sx={{ flexGrow: 1 }}>
                <Table size='small'>
                    {!hideName && <TableRow>
                        <TableCell colSpan={3} align='center'><Typography >{name}</Typography></TableCell>
                    </TableRow>}
                    {showId && <TableRow>
                        <TableCell><Typography fontSize={10}>ID</Typography></TableCell>
                        <TableCell colSpan={2} ><Typography fontSize={10}>{id}</Typography></TableCell>
                    </TableRow>}
                    {!hideDescription && <TableRow>
                        <TableCell><Typography fontSize={12}>{t('Description', { ns: 'tanks' })}</Typography></TableCell>
                        <TableCell colSpan={2} ><Typography fontSize={12}>{description || '—'}</Typography></TableCell>
                    </TableRow>}
                    {!hideVarieties && <TableRow>
                        <TableCell><Typography fontSize={12}>{t('Varieties', { ns: 'tanks' })}</Typography></TableCell>
                        <TableCell colSpan={2} ><VarietiesToChip varieties={varieties} /></TableCell>
                    </TableRow>}
                    {!hideFraction && <TableRow>
                        <TableCell><Typography fontSize={12}>{t('Fraction', { ns: 'tanks' })}</Typography></TableCell>
                        <TableCell colSpan={2} ><FractionName fontSize={12} id={fraction} /></TableCell>
                    </TableRow>}
                    {!hideYear && <TableRow>
                        <TableCell><Typography fontSize={12}>{t('Year', { ns: 'tanks' })}</Typography></TableCell>
                        <TableCell><Typography fontSize={12}>{year || '—'}</Typography></TableCell>
                        <TableCell rowSpan={4}><TankRemainingVolumeGraph tank={tank} /></TableCell>
                    </TableRow>}
                    {!hideVolume && <TableRow>
                        <TableCell><Typography fontSize={12}>{t('Volume', { ns: 'tanks' })}</Typography></TableCell>
                        <TableCell><Typography fontSize={12}>{`${volume} hL`}</Typography></TableCell>
                    </TableRow>}
                    {!hideRemainingVolume &&
                        <TableRow>
                            <TableCell><Typography fontSize={12}>{t('Remaining Volume', { ns: 'tanks' })}</Typography></TableCell>
                            <TableCell><TankRemainingVolumeValue fontSize={12} tank={tank} /></TableCell>
                        </TableRow>
                    }
                </Table>
            </CardContent>
            <CardActions>
                <Stack sx={{ width: '100%' }} direction='row' justifyContent='center'>
                    {!hideViewAction && <Button size="small" onClick={handleClick} >{t('View')}</Button>}
                    {!hideRemoveAction && <TankDeleteButton tank={tank} />}
                </Stack>
            </CardActions>
        </Card >
    )
}
