import React from 'react';
import { Stack } from '@mui/material';
import _ from 'lodash';
import TanksInBlendCard from '../tanksinblend/tanks-in-blend-card';
import { withTanksInBlendInBlendList } from './observables';
import AddTankToBlendButton from '../tanksinblend/add-tank-to-blend-button';

export function TanksUsedInBlendsListItem({ tankInBlend }) {

    return (
        <TanksInBlendCard tankInBlend={tankInBlend} />
    )
}

export function TanksUsedInBlendsListInternal({ blend, tanksInBlend, hideAddButton = false }) {

    return (
        <Stack direction='row' alignItems='center' flexWrap={1} spacing={2}>
            {_.map(tanksInBlend, (tankInBlend) =>
                <TanksUsedInBlendsListItem key={tankInBlend.id} tankInBlend={tankInBlend} />
            )}
            {!hideAddButton && <AddTankToBlendButton blend={blend} />}
        </Stack>
    )
}

const TanksUsedInBlendsList = withTanksInBlendInBlendList(TanksUsedInBlendsListInternal);

export default TanksUsedInBlendsList;
