import React from 'react';
import AppBar from '../components/AppBar';
import { Grid } from '@mui/material';
import { useOutlet, useParams } from 'react-router-dom';
import SeasonProvider from '../components/seasons/seasons-context';
import SideBarWinemaker from '../components/SideBarWinemaker';
import SeasonDashboard from '../components/seasons/season-dashboard';

function WinemakerDetails() {

    const outlet = useOutlet();
    const { winemaker_id, season_id } = useParams();

    return (
        <SeasonProvider season_id={season_id} winemaker_id={winemaker_id}>
            <AppBar sidebar={<SideBarWinemaker />}>
                {outlet ? outlet : (
                    <Grid container spacing={2}>

                        <Grid display='flex' flexGrow={1} item xs={12}>
                            <SeasonDashboard />
                        </Grid>
                    </Grid>
                )}
            </AppBar>
        </SeasonProvider>
    );
}

export default WinemakerDetails;
