import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import _ from 'lodash';
import TankCard from './tank-card';
import TanksCreateButton from './tank-create-button';
import { withTanksInBlendsPeriodList } from '../blends-period/observables';

export function TanksListInternal({ tanks, onClick, hideAddButton = false, ...props }) {

    return (
        <Grid {...props} container spacing={2} alignItems='center'>
            {_.map(tanks, (tank) =>
                <Grid key={tank.id} item xs={12} sm={6} md={3}>
                    <TankCard tank={tank} onClick={onClick} />
                </Grid>

            )}
            {!hideAddButton && (
                <Grid item xs={12} sm={6} md={3} textAlign='center'>
                    <Card variant='outlined'>
                        <CardContent>
                            <TanksCreateButton />
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </Grid>
    )
}

const TanksList = withTanksInBlendsPeriodList(TanksListInternal);

export default TanksList;
