import React, { Fragment, useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Formik, useField } from 'formik';
import * as Yup from 'yup';
import { Q } from '@nozbe/watermelondb'
import { useAddTankToBlend } from '../blends/hooks';
import TanksSelect from '../tanks/tanks-select';
import { withTanksInBlendInBlendList } from '../blends/observables';
import { useComputeRemainingBlendVolumeLiter } from '../blends/remaining-blend-volume';
import { useFindTank } from '../tanks/hooks';
import { computeRemainingTankVolumeLiter } from '../tanks/tank-remaining-volume-graph';
import _, { min } from 'lodash';
import { useTranslation } from 'react-i18next';

export function TankSelectField({ blend, name, onTankChange, notIn = [], ...props }) {

    const [field] = useField(name);
    const findTank = useFindTank();

    const handleChange = async (ev) => {
        const id = ev.target.value;
        const tank = await findTank(id);
        if (tank) {
            const tanksInBlend = await tank.tanksInBlend.fetch();
            const remainingVolume = computeRemainingTankVolumeLiter(tank, tanksInBlend);
            onTankChange({ tank, remainingVolume });
        } else {
            field.onChange(ev);
            onTankChange(null);
        }
    }

    return (
        <TanksSelect {...props}
            {...field}
            onChange={handleChange}
            query={Q.where('id', Q.notIn(notIn))}
        />
    )
}

export function TankVolumeField({ blend, name = 'volume', max, ...props }) {

    const { t } = useTranslation();
    const [field] = useField(name);

    return (
        <TextField
            InputProps={{
                endAdornment: <InputAdornment position="end">{t('hL')}</InputAdornment>
            }}
            inputProps={{
                min: 0,
                max
            }}
            {...props}
            {...field}
            type='number' />
    )
}

export function TankPercentageField({ targetVolume, name = 'volume', max, ...props }) {

    const [field] = useField(name);
    const { t } = useTranslation();

    const volume = field.value;

    const percentage = volume / targetVolume * 100;
    const percentageMax = max / targetVolume * 100;

    const handleChange = (ev) => {
        const percentage = parseFloat(ev.target.value);
        const volume = percentage * targetVolume / 100;
        ev.target.value = `${volume}`;
        field.onChange(ev);
    }

    return (
        <TextField
            {...props}
            {...field}
            type='number'
            InputProps={{
                endAdornment: <InputAdornment position="end">{t('%')}</InputAdornment>
            }}
            inputProps={{
                min: 0,
                max: percentageMax
            }}
            onChange={handleChange}
            value={percentage} />
    )
}

const schema = Yup.object().shape({
    tank_id: Yup.string().required('must be set'),
    volume: Yup.number().moreThan(0, 'must be greater than 0').required('must be set'),
});

function AddTankToBlendModalInternal({ blend, tanksInBlend, open, onClose }) {

    const { t } = useTranslation(['translation', 'tanksinblend']);
    const addTankToBlend = useAddTankToBlend();
    const remainingVolume = useComputeRemainingBlendVolumeLiter(blend, tanksInBlend);

    const [tankBundle, setTankBundle] = useState(null);
    const maxVolume = tankBundle ? min([tankBundle.remainingVolume, remainingVolume]) : remainingVolume;

    const initialValues = useMemo(() => ({
        tank_id: tankBundle ? tankBundle.tank.id : '',
        volume: maxVolume,
    }), [tankBundle, maxVolume]);

    return (
        <Dialog open={open} onClose={onClose}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={schema}
                onSubmit={async ({ tank_id, volume }, { setSubmitting }) => {
                    await addTankToBlend(blend, { tank_id, volume });
                    setSubmitting(false);
                    onClose();
                }}
            >
                {props => {

                    const disabled = props.values['tank_id'] === '' || props.values['volume'] === 0;

                    return (
                        <Fragment>
                            <DialogTitle>
                                {t('Add a tank to the blend', { ns: 'tanksinblend' })}
                            </DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2} sx={{ p: 2 }}>
                                    <Grid item xs={12}>
                                        <TankSelectField
                                            fullWidth
                                            variant="outlined"
                                            label={t('Select', { ns: 'tanksinblend' })}
                                            name="tank_id"
                                            onTankChange={setTankBundle}
                                            blend={blend}
                                            notIn={_.map(tanksInBlend, tib => tib._raw.tank_id)}
                                        />
                                    </Grid>
                                    <Grid sx={{ mt: 2 }} item xs={12}>
                                        <TankPercentageField
                                            fullWidth
                                            max={maxVolume}
                                            targetVolume={blend.volume}
                                            label={t('Percentage', { ns: 'tanksinblend' })}
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TankVolumeField
                                            fullWidth
                                            max={maxVolume}
                                            label={t('Volume', { ns: 'tanksinblend' })}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button color='inherit' onClick={onClose}>
                                    {t('Cancel')}
                                </Button>
                                <Button disabled={disabled} color='primary' onClick={props.submitForm}>
                                    {t('Add')}
                                </Button>
                            </DialogActions>
                        </Fragment>
                    )
                }}
            </Formik>
        </Dialog >
    )
}

export const AddTankToBlendModal = withTanksInBlendInBlendList(AddTankToBlendModalInternal);

export default function AddTankToBlendButton({ blend }) {

    const [open, setOpen] = useState(false);

    const handleClickOpen = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(true);
    };

    const handleClose = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(false);
    };

    return (
        <Box sx={{ p: 2 }}>
            <IconButton onClick={handleClickOpen}>
                <AddIcon />
            </IconButton>
            <AddTankToBlendModal open={open} onClose={handleClose} blend={blend} />
        </Box>
    )
}
