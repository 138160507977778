import withObservables from '@nozbe/with-observables'
import { withDatabase } from '../../models/underground/database';
import { compose } from 'recompose'
import { blendsTableName } from '../../models/Blends';

export const withBlend = compose(
    withDatabase,
    withObservables(['blend_id'], ({ database, blend_id }) => ({
        blend: database.get(blendsTableName).findAndObserve(blend_id)
    }))
)

export const withTanksInBlendInBlendList = compose(
    withObservables(['blend'], ({ blend }) => ({
        blend,
        tanksInBlend: blend.tanksInBlend,
    }))
);

