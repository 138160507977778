import React from 'react';
import { Divider, List } from '@mui/material';

import BuildCircleIcon from '@mui/icons-material/BuildCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';

import { useTranslation } from 'react-i18next';
import SideBar, { SidebarElement } from './SideBar';

export default function SideBarDashboard() {

    const { t } = useTranslation();

    return (
        <SideBar>
            <List>
                <SidebarElement href='/' icon={<DashboardIcon />} text={t('Dashboard')} />
                <SidebarElement href='w' icon={<PeopleIcon />} text={t('Winemakers')} />
                <SidebarElement href='t' icon={<BuildCircleIcon />} text={t('Tools')} />
            </List>
            <Divider />
        </SideBar>
    );
}