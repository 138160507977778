import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useOutlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HarvestPeriodProvider from '../components/harvest-period/harvest-period-context';

export function HarvestPeriodPageWrapper() {
    const outlet = useOutlet();

    return (
        <HarvestPeriodProvider>
            {outlet ? outlet : <HarvestPeriodPage />}
        </HarvestPeriodProvider>
    );
}

function HarvestPeriodPage() {
    const { t } = useTranslation();

    return (
        <Stack flexGrow={1} direction='column' alignItems='flex-start'>
            <Typography variant='h4'>{t('Harvest')}</Typography>
        </Stack>
    )
}

export default HarvestPeriodPage;
