import React from "react";
import { createBrowserRouter, redirect, RouterProvider } from "react-router-dom";
import ToolsList from "../components/tools/ToolsList";
import PersonnalDashboard from "../components/users/dashboard/PersonnalDashboard";
import BlendPage from "./blend-page";
import BlendsPeriodPage, { BlendsPeriodPageWrapper } from "./blends-period-page";
import HarvestPeriodPage, { HarvestPeriodPageWrapper } from "./harvest-period-page";
import PullingsPeriodPage, { PullingsPeriodPageWrapper } from "./pullings-period-page";
import Root from "./root";
import TankPage from "./tank-page";
import Winemakers from "./winemakers";
import WinemakerDetails from "./winemakers-details";

const loaderRedirect = (location) => async () => {
    return redirect(location);
};

const router = createBrowserRouter([
    {
        path: "/",
        loader: loaderRedirect('/d'),
        element: null,
    },
    {
        path: "/d",
        element: <Root />,
        children: [
            {
                path: "",
                element: <PersonnalDashboard />,
            },
            {
                path: "w",
                element: <Winemakers />,
            },
            {
                path: "t",
                element: <ToolsList />,
            },
        ]
    },
    {
        path: "/w/:winemaker_id/:season_id?",
        element: <WinemakerDetails />,
        children: [
            {
                path: "h",
                element: <HarvestPeriodPageWrapper />,
                children: [
                    {
                        path: "*",
                        element: <HarvestPeriodPage />,
                    },
                ]
            },
            {
                path: "bp",
                element: <BlendsPeriodPageWrapper />,
                index: <BlendsPeriodPage />,
                children: [
                    {
                        path: "b/:blend_id",
                        element: <BlendPage />,
                    },
                    {
                        path: "t/:tank_id",
                        element: <TankPage />,
                    },
                    {
                        path: "*",
                        element: <BlendsPeriodPage />,
                    },
                ]
            },
            {
                path: "p",
                element: <PullingsPeriodPageWrapper />,
                index: <PullingsPeriodPage />,
                children: [
                    {
                        path: "*",
                        element: <PullingsPeriodPage />,
                    },
                ]
            },
        ]
    },
]);


export default function AppRouter() {
    return <RouterProvider router={router} />
}
