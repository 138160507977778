import withObservables from '@nozbe/with-observables'
import { compose } from 'recompose'

export const withObservedTankInBlend = compose(
    withObservables(['tankInBlend'], ({ tankInBlend }) => ({
        tankInBlend,
        tank: tankInBlend.tank,
        blend: tankInBlend.blend,
    }))
);

