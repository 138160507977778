import React, { createContext, useContext, } from 'react';
import { withContext } from '../misc/withContext';
import { withHarvestPeriodInSeason } from '../seasons/observables';
import HarvestPeriodCreate from './harvest-period-create';

const HarvestPeriodContext = createContext({
    harvestPeriod: null
});

export const useHarvestPeriod = () => useContext(HarvestPeriodContext);
export const withHarvestPeriodInCtx = withContext(HarvestPeriodContext, ({ harvestPeriod }) => ({
    harvestPeriod,
}));

function HarvestPeriodProviderInternal({ season, harvestPeriod, children }) {

    if (harvestPeriod === null) {
        return <HarvestPeriodCreate season={season} />
    }

    return (
        <HarvestPeriodContext.Provider value={{ harvestPeriod }}>
            {children}
        </HarvestPeriodContext.Provider>
    )

}

const HarvestPeriodProvider = withHarvestPeriodInSeason(HarvestPeriodProviderInternal);

export default HarvestPeriodProvider;
