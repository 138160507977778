import React, { useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogTitle, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteTankInBlend } from './hooks';
import { useTranslation } from 'react-i18next';

export function TankInBlendDeleteModal({ tankInBlend, open, onClose }) {

    const { t } = useTranslation(['translation', 'tanksinblend'])
    const deleteTankInBlend = useDeleteTankInBlend();
    const handleClick = async (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        await deleteTankInBlend(tankInBlend);
        onClose();
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {t('Remove the tank', { ns: 'tanksinblend' })}
            </DialogTitle>
            <DialogActions>
                <Button color='inherit' onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button color='primary' onClick={handleClick}>
                    {t('Confirm')}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default function TankInBlendDeleteButton({ tankInBlend }) {

    const [open, setOpen] = useState(false);

    const handleClickOpen = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(true);
    };

    const handleClose = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(false);
    };

    return (
        <Box sx={{ p: 2 }}>
            <IconButton onClick={handleClickOpen}>
                <DeleteIcon />
            </IconButton>
            <TankInBlendDeleteModal open={open} onClose={handleClose} tankInBlend={tankInBlend} />
        </Box>
    )
}
