import React from 'react';
import { useTheme } from '@mui/material';
import InlineTextField from './InlineTextField';

export default function DerogatedTextField({ derogated, ...props }) {

    const theme = useTheme();
    const inputProps = props?.inputProps;

    return (
        <InlineTextField
            {...props}
            inputProps={Object.assign({
                sx: {
                    color: derogated ? theme.palette.warning.main : undefined,
                },
            }, inputProps)}
            color={derogated ? 'warning' : undefined}
        />

    )
}
