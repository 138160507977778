import { Button } from '@mui/material';
import React from 'react';
import { useResetDB } from '../../../models/underground/database';

export default function ResetDatabaseButton(props) {

    const reset = useResetDB();

    return (
        <Button {...props} onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            reset().then(() => window.location.replace('/'))
        }}>
            Reset
        </Button>
    )
}