import React from 'react';
import { Typography } from '@mui/material';
import { getFractionById } from '../../models/Fractions';
import { useTranslation } from 'react-i18next';


export default function FractionName({ id, ...props }) {

    const fraction = getFractionById(id);
    const { t } = useTranslation('fractions');

    return <Typography {...props}>{fraction ? t(fraction.name) : '—'}</Typography>
}
