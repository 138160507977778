import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { GROUP_CHAMPAGNE } from '../../models/Varieties';
import { Typography } from '@mui/material';
import { fractionsFor } from '../../models/Fractions';
import { useTranslation } from 'react-i18next';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function FractionSelect({ value = null, onChange = (ev) => { }, group = GROUP_CHAMPAGNE, label, fullWidth = false, ...props }) {

    const FRACTIONS = fractionsFor(group);
    const {t} = useTranslation('fractions');

    return (
        <FormControl fullWidth={fullWidth}>
            <InputLabel id="fractions-chip-label">{label}</InputLabel>
            <Select
                {...props}
                labelId="fractions-chip-label"
                value={value}
                onChange={onChange}
                MenuProps={MenuProps}
            >
                {FRACTIONS.map((fraction) => (
                    <MenuItem
                        key={fraction.id}
                        value={fraction.id}
                    >
                        <Typography>{t(fraction.name)}</Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
