import React from 'react';
import { inputAdornmentClasses, inputBaseClasses, TextField, typographyClasses, useTheme } from '@mui/material';
import _ from 'lodash';

export default function InlineTextField({ fontSize = '1rem', value, ...props }) {

    const theme = useTheme();

    const forcedProps = {
        variant: 'standard',
        fullWidth: false,
        value,
        InputProps: {

        },
        sx: {
            [`& .${inputBaseClasses.input}`]: {
                padding: 0,
                paddingTop: '2px',
                width: `${value.toString().length}ch`
            },
            [`& .${inputBaseClasses.input}[type=number]::-webkit-inner-spin-button`]: {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            [`& .${inputBaseClasses.input}[type=number]::-webkit-outer-spin-button`]: {
                '-webkit-appearance': 'none',
                margin: 0,
            },
            [`& .${inputBaseClasses.root}`]: {
                fontSize,
            },
            [`& .${inputAdornmentClasses.root}`]: {
                marginLeft: 0.5,
            },
            [`& .${typographyClasses.root}`]: {
                fontSize,
                color: theme.palette.text.primary
            }
        }
    }

    const weakProps = {
    }

    const innerProps = _.merge(weakProps, props, forcedProps);

    return (
        <TextField
            {...innerProps}
        />
    )
}
