import React from 'react';
import AppBar from '../components/AppBar';
import { useOutlet } from 'react-router-dom';
import SideBarDashboard from '../components/SideBarDashboard';

function Root() {

    const outlet = useOutlet();

    return (
        <AppBar sidebar={<SideBarDashboard />}>
            {outlet}
        </AppBar>
    );
}

export default Root;
