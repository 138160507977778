import withObservables from '@nozbe/with-observables'
import { compose } from 'recompose'
import { withDatabase } from '../../models/underground/database';
import { tanksTableName } from '../../models/Tanks';

export const withObservedTanksInBlendsInTank = compose(
    withObservables(['tank'], ({ tank }) => ({
        tank,
        tanksInBlend: tank.tanksInBlend,
    }))
);

export const withTank = compose(
    withDatabase,
    withObservables(['tank_id'], ({ database, tank_id }) => ({
        tank: database.get(tanksTableName).findAndObserve(tank_id)
    }))
)