import withObservables from '@nozbe/with-observables'
import { compose } from 'recompose'
import { withSeasonCtx } from './seasons-context';

export const withHarvestPeriodInSeason = compose(
    withSeasonCtx,
    withObservables(['season'], ({ season }) => ({
        harvestPeriod: season.harvestPeriod
    }))
)

export const withBlendsPeriodInSeason = compose(
    withSeasonCtx,
    withObservables(['season'], ({ season }) => ({
        blendsPeriod: season.blendsPeriod,
    }))
)

export const withPullingsPeriodInSeason = compose(
    withSeasonCtx,
    withObservables(['season'], ({ season }) => ({
        pullingsPeriod: season.pullingsPeriod,
    }))
)
