import { tableSchema, Model } from '@nozbe/watermelondb'
import { date, field, json, relation, } from '@nozbe/watermelondb/decorators'
import { leavensTableName, leavenStepsTableName } from './Leavens';

export const LEAVEN_STEPS_TYPES = ['rehydration', 'adapt', 'multiplication']

export const leavenStepsSchema = tableSchema({
    name: leavenStepsTableName,
    columns: [
        { name: 'type', type: 'string' }, // type of step
        { name: 'leaven', type: 'string' }, // id of the corresponding leaven.
        { name: 'duration', type: 'number' }, // time in milliseconds.
        { name: 'date', type: 'number' }, // instant when to do the step.
        { name: 'temperature', type: 'number' }, // temperature of the tank in °C.
        { name: 'content', type: 'string' }, // json data.
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
    ]
});

export default class LeavenSteps extends Model {
    static table = leavenStepsTableName
    static associations = {
        [leavensTableName]: { type: 'belongs_to', key: 'leaven' }
    }

    @relation(leavensTableName, 'leaven') leaven
    @field('type') type
    @field('duration') duration
    @date('date') date
    @field('temperature') temperature
    @json('content', (source) => source) content

    @date('created_at') createdAt
    @date('updated_at') updatedAt
}
