import React, { Fragment, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useCreateWinemaker } from './hooks';
import { useTranslation } from 'react-i18next';

export function WinemakersCreateModal({ open, onClose }) {

    const { t } = useTranslation(["translation", "winemakers"]);
    const createWinemaker = useCreateWinemaker();

    return (
        <Dialog open={open} onClose={onClose}>
            <Formik
                initialValues={{
                    name: '',
                    description: '',
                }}
                onSubmit={async (winemaker, { setSubmitting }) => {
                    await createWinemaker(winemaker);
                    setSubmitting(false);
                    onClose();
                }}
            >
                {props => (
                    <Fragment>
                        <DialogTitle>
                            {t('Add a winemaker', { ns: "winemakers" })}
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} sx={{ p: 2 }}>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        component={TextField}
                                        label={t("Name", { ns: "winemakers" })}
                                        name="name"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        component={TextField}
                                        label={t("Description", { ns: "winemakers" })}
                                        name="description"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button color='inherit' onClick={onClose}>
                                {t('Cancel')}
                            </Button>
                            <Button color='primary' onClick={props.submitForm}>
                                {t('Add')}
                            </Button>
                        </DialogActions>
                    </Fragment>
                )}
            </Formik>
        </Dialog >
    )
}

export default function WinemakerCreateButton() {

    const [open, setOpen] = useState(false);

    const handleClickOpen = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(true);
    };

    const handleClose = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(false);
    };

    return (
        <Box sx={{ p: 2 }}>
            <IconButton onClick={handleClickOpen}>
                <AddIcon />
            </IconButton>
            <WinemakersCreateModal open={open} onClose={handleClose} />
        </Box>
    )
}
