import { tableSchema, Model } from '@nozbe/watermelondb'
import { date, relation } from '@nozbe/watermelondb/decorators'
import moment from 'moment';

export const pullingsPeriodTableName = 'pullingsperiod';

export const pullingsPeriodSchema = tableSchema({
    name: pullingsPeriodTableName,
    columns: [
        { name: 'season_id', type: 'string' },
        { name: 'ended_at', type: 'number', isOptional: true },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
    ]
});

export default class PullingsPeriod extends Model {
    static table = pullingsPeriodTableName
    static associations = {
        seasons: { type: 'belongs_to', key: 'season_id' },
    }

    @relation('seasons', 'season_id') season
    @date('ended_at') endedAt

    @date('created_at') createdAt
    @date('updated_at') updatedAt
}

export async function createPullingsPeriod(database, season) {
    await database.write(async () => {
        const newPullingsPeriod = await database
            .get(pullingsPeriodTableName)
            .create(p => {
                p.season.set(season);
            });
        await season.update(() => {
            season.pullingsPeriod.set(newPullingsPeriod);
        });
        return newPullingsPeriod;
    });
}

export async function endPullingsPeriod(database, pullingsPeriod) {
    await database.write(async () => {
        await pullingsPeriod.update((p) => {
            p.endedAt = moment().valueOf();
        });
    });
}