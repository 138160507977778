import _ from "lodash";

export const GROUP_CHAMPAGNE = 'champagne';

export const CHARDONNAY = { name: "chardonnay", id: "chardonnay", groups: [GROUP_CHAMPAGNE], color: '#ffffb3' };
export const PINOTNOIR = { name: "pinot noir", id: "pinot_noir_white", groups: [GROUP_CHAMPAGNE], color: '#ffcc99' };
export const PINOTNOIR_RED = { name: "pinot noir", id: "pinot_noir_red", groups: [GROUP_CHAMPAGNE], color: '#990000' };
export const PINOTNOIR_ROSEE = { name: "pinot noir", id: "pinot_noir_rose", groups: [GROUP_CHAMPAGNE], color: '#ff7070' };
export const PINOTMEUNIER = { name: "pinot meunier", id: "pinot_meunier", groups: [GROUP_CHAMPAGNE], color: '#ffcc99' };
export const PINOTBLANC = { name: "pinot blanc", id: "pinot_blanc", groups: [GROUP_CHAMPAGNE], color: '#ffffcc' };
export const ARBANNE = { name: "arbanne", id: "arbanne", groups: [GROUP_CHAMPAGNE], color: '#ffffcc' };
export const PINOTGRIS = { name: "pinot gris", id: "pinot_gris", groups: [GROUP_CHAMPAGNE], color: '#ffffcc' };
export const PETITMESLIER = { name: "petit meslier", id: "petit_meslier", groups: [GROUP_CHAMPAGNE], color: '#ffffcc' };

export const VARIETIES = [CHARDONNAY, PINOTNOIR_RED, PINOTNOIR_ROSEE, PINOTNOIR, PINOTMEUNIER, PINOTBLANC, ARBANNE, PINOTGRIS, PETITMESLIER];

export const champagneVarietes = () => _.filter(VARIETIES, (v) => v.groups.includes(GROUP_CHAMPAGNE));
export const varietiesFor = (group) => _.filter(VARIETIES, (v) => v.groups.includes(group));
