import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import _ from 'lodash';
import BlendCard from './blend-card';
import BlendsCreateButton from './blend-create-button';
import { withBlendsInBlendsPeriodList } from '../blends-period/observables';

export function BlendsListInternal({ blends, onClick, hideAddButton = false, ...props }) {

    return (
        <Grid {...props} container spacing={2} alignItems='center'>
            {_.map(blends, (blend) =>
                <Grid key={blend.id} item xs={12} sm={6} md={3}>
                    <BlendCard blend={blend} hideSugar onClick={onClick} />
                </Grid>
            )}
            {!hideAddButton && (
                <Grid item xs={12} sm={6} md={3} textAlign='center'>
                    <Card variant='outlined'>
                        <CardContent>
                            <BlendsCreateButton />
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </Grid>
    )
}

const BlendsList = withBlendsInBlendsPeriodList(BlendsListInternal);

export default BlendsList;
