import React from 'react';
import _ from 'lodash';
import { LinearProgress, Typography } from '@mui/material';
import { withTanksInBlendInBlendList } from './observables';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useTranslation } from 'react-i18next';

export const useComputeRemainingBlendVolumeLiter = (blend, tanksInBlend) => {
    return _.reduce(tanksInBlend, (remainingVolume, tankInBlend) => remainingVolume - tankInBlend.volume, blend.volume);
}

export const useComputeRemainingBlendVolumePercentage = (blend, tanksInBlend) => {
    const remainingVolumeLiter = useComputeRemainingBlendVolumeLiter(blend, tanksInBlend);
    return [remainingVolumeLiter / blend.volume * 100, remainingVolumeLiter];
}

function RemainingBlendVolumeMessageInternal({ blend, tanksInBlend, ...props }) {
    const [remainingVolumePercentage, remainingVolumeLiter] = useComputeRemainingBlendVolumePercentage(blend, tanksInBlend);
    const {t} =useTranslation(['translation', 'blends']);

    if (remainingVolumePercentage <= 0) {
        return (
            <Typography {...props}>{t('Blend Completed', {ns: 'blends'})}</Typography>
        )
    } else {
        return (
            <Typography {...props}>{remainingVolumeLiter.toFixed(1)} {t('hL')}</Typography>
        )
    }
}

export const RemainingBlendVolumeMessage = withTanksInBlendInBlendList(RemainingBlendVolumeMessageInternal);

export function RemainingBlendVolumeGraphInternal({ blend, tanksInBlend, ...props }) {
    // eslint-disable-next-line
    const [remainingVolumeLiter, remainingVolumePercentage] = useComputeRemainingBlendVolumePercentage(blend, tanksInBlend);

    if (remainingVolumePercentage <= 0) {
        return (
            <CheckCircleOutlineIcon color='success' />
        )
    } else {
        return (
            <LinearProgress {...props} color='success' variant='determinate' value={100 - remainingVolumePercentage} />
        )
    }
}

export const RemainingBlendVolumeGraph = withTanksInBlendInBlendList(RemainingBlendVolumeGraphInternal);
