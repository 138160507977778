import { useDatabase } from '../../models/underground/database';
import { useCallback } from 'react';
import { createWinemaker } from '../../models/Winemaker';

export const useCreateWinemaker = () => {
    const database = useDatabase();

    return useCallback(async (winemaker) => {
        await createWinemaker(database, winemaker);
    }, [database]);
}


