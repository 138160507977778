import { Model, tableSchema } from '@nozbe/watermelondb'
import { date, field, relation } from '@nozbe/watermelondb/decorators';

export const tanksInBlendTableName = 'tanksinblends';

export const tanksInBlendsSchema = tableSchema({
    name: tanksInBlendTableName,
    columns: [
        { name: 'blend_id', type: 'string' },
        { name: 'tank_id', type: 'string' },
        { name: 'volume', type: 'number' },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
    ]
});

export default class TanksInBlends extends Model {
    static table = tanksInBlendTableName
    static associations = {
        'blends': { type: 'belongs_to', key: 'blend_id' },
        'tanks': { type: 'belongs_to', key: 'tank_id' },
    }

    @relation('blends', 'blend_id') blend
    @relation('tanks', 'tank_id') tank
    @field('volume') volume

    @date('created_at') createdAt
    @date('updated_at') updatedAt
}

export async function addTankToBlend(database, tankinblend) {
    await database.write(async () => {
        const newTank = await database.get(tanksInBlendTableName).create(tib => {
            tib.blend.id = tankinblend.blend_id;
            tib.tank.id = tankinblend.tank_id;
            tib.volume = tankinblend.volume;
        })
        return newTank;
    });
}

export async function deleteTankInBlend(database, tankInBlend) {
    await database.write(async () => {
        await tankInBlend.markAsDeleted();
    });
}
