import React from 'react';
import { Divider, List } from '@mui/material';

import HubIcon from '@mui/icons-material/Hub';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import DashboardIcon from '@mui/icons-material/Dashboard';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { useTranslation } from 'react-i18next';
import SideBar, { SidebarElement } from './SideBar';
import { useNavigate, useParams } from 'react-router-dom';
import WinemakerNameInList from './winemakers/winemaker-name-in-list';

export default function SideBarWinemaker() {

    const { t } = useTranslation();
    const { winemaker_id } = useParams();
    const navigate = useNavigate();

    return (
        <SideBar defaultOpen>
            <List>
                <SidebarElement href='/' icon={<DashboardIcon />} text={t('Dashboard')} />
            </List>
            <Divider />
            <List sx={{ mt: -3 }}>
                <WinemakerNameInList buttonProps={{ onClick: () => navigate(''), selected: true }} winemaker_id={winemaker_id} disablePadding sx={{ display: 'block', mt: 2, mb: 2 }} />
            </List>
            <Divider sx={{ mt: -3 }} />
            <List>
                <SidebarElement href='h' icon={<AgricultureIcon />} text={t('Harvest')} />
                <SidebarElement href='bp' icon={<HubIcon />} text={t('Blends')} />
                <SidebarElement href='p' icon={<KeyboardDoubleArrowDownIcon />} text={t('Pulling')} />
            </List>
            <Divider />
        </SideBar>
    );
}