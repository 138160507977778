import React from 'react';
import { Card, CardActions, CardContent, Stack, Table, TableCell, TableRow, Typography } from '@mui/material';
import { withObservedTankInBlend } from './observables';
import TankInBlendDeleteButton from './delete-tanks-in-blend-button';
import TankCard from '../tanks/tank-card';
import { useTranslation } from 'react-i18next';

export function TanksInBlendCardInternal({ tank, blend, tankInBlend,
    showId = false, hideTankDesc = false, hideVolume = false, hidePercentage = false,
    hideRemoveAction = false }) {

    const { t } = useTranslation(['translation', 'tanksinblend']);

    const { id, volume } = tankInBlend;
    const { volume: blendVolume } = blend;

    const percentageInBlend = volume / blendVolume * 100;

    return (
        <Card >
            <CardContent>
                <Table size='small'>
                    {!hideTankDesc && <TableRow>
                        <TableCell colSpan={2}>
                            <TankCard elevation={0} sx={{ mb: 4 }} tank={tank} hideDescription hideFraction hideYear hideRemoveAction hideViewAction />
                        </TableCell>
                    </TableRow>}
                    {showId && <TableRow>
                        <TableCell><Typography fontSize={10}>ID</Typography></TableCell>
                        <TableCell><Typography fontSize={10}>{id}</Typography></TableCell>
                    </TableRow>}
                    {!hideVolume && <TableRow>
                        <TableCell><Typography fontSize={12}>{t('Volume', {ns: 'tanksinblend'})}</Typography></TableCell>
                        <TableCell><Typography fontSize={12}>{volume} {t('hL')}</Typography></TableCell>
                    </TableRow>}
                    {!hidePercentage && <TableRow>
                        <TableCell><Typography fontSize={12}>{t('Percentage', {ns: 'tanksinblend'})}</Typography></TableCell>
                        <TableCell><Typography fontSize={12}>{percentageInBlend.toFixed(2)} {t('%')}</Typography></TableCell>
                    </TableRow>}
                </Table>
            </CardContent>
            <CardActions>
                <Stack sx={{ width: '100%' }} direction='row' alignItems='center' justifyContent='center'>
                    {!hideRemoveAction && <TankInBlendDeleteButton tankInBlend={tankInBlend} />}
                </Stack>
            </CardActions>
        </Card>
    )
}

const TanksInBlendCard = withObservedTankInBlend(TanksInBlendCardInternal);

export default TanksInBlendCard;
