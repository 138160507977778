import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { Database } from '@nozbe/watermelondb'
import { hasUnsyncedChanges, synchronize } from '@nozbe/watermelondb/sync'
import { adapter } from './adapter';

import Blend from '../Blends';
import Tank from '../Tanks';
import Winemaker from '../Winemaker';
import TanksInBlends from '../TanksInBlends';
import Season from '../Seasons';
import Leavens from '../Leavens';
import LeavenSteps from '../LeavenSteps';
import HarvestPeriod from '../HarvestPeriod';
import BlendsPeriod from '../BlendPeriod';
import PullingsPeriod from '../PullingsPeriod';

const DatabaseContext = createContext({
    database: null,
});

export const useDatabase = () => useContext(DatabaseContext).database;
export function withDatabase(Component) {
    return function DatabasedComponent(props) {
        return (
            <DatabaseContext.Consumer>
                {({ database }) => <Component {...props} database={database} />}
            </DatabaseContext.Consumer>
        )
    }
}

export default function DatabaseProvider({ children }) {

    const [database] = useState(new Database({
        adapter,
        modelClasses: [
            Blend,
            Tank,
            TanksInBlends,
            Leavens,
            LeavenSteps,
            HarvestPeriod,
            BlendsPeriod,
            PullingsPeriod,
            Season,
            Winemaker,
        ],
    }))

    return (
        <DatabaseContext.Provider value={{ database }}>
            {children}
        </DatabaseContext.Provider>
    )
}

export const useDBHasChanges = () => {
    const database = useDatabase();
    const [changes, setChanges] = useState(null);

    const check = useCallback(async () => {
        const changes = await hasUnsyncedChanges({ database });
        setChanges(changes);
        return changes;
    }, [database]);

    useEffect(() => {
        const timeout = setInterval(() => {
            check().catch(e => console.error(e));
        }, 1000);
        return () => {
            clearInterval(timeout);
        }
    }, [database, check]);

    return [changes, check];
}

export const useSynchronizeDB = () => {
    const database = useDatabase();
    return useCallback(async () => await synchronize({
        database,
        pullChanges: async ({ lastPulledAt, schemaVersion, migration }) => {
            
        },
        pushChanges: async ({ changes, lastPulledAt }) => {
            
        },
        migrationsEnabledAtVersion: 1,
    }), [database]);
}

export const useResetDB = () => {
    const database = useDatabase();
    return useCallback(async () => await database.write(async () => await database.unsafeResetDatabase()), [database]);
}