import { appSchema } from '@nozbe/watermelondb'
import { blendsPeriodSchema } from '../BlendPeriod'
import { blendsSchema } from '../Blends'
import { harvestPeriodSchema } from '../HarvestPeriod'
import { leavensSchema } from '../Leavens'
import { leavenStepsSchema } from '../LeavenSteps'
import { pullingsPeriodSchema } from '../PullingsPeriod'
import { seasonsSchema } from '../Seasons'
import { tanksSchema } from '../Tanks'
import { tanksInBlendsSchema } from '../TanksInBlends'
import { winemakersSchema } from '../Winemaker'

export default appSchema({
    version: 1,
    tables: [
        tanksSchema,
        blendsSchema,
        tanksInBlendsSchema,
        leavensSchema,
        leavenStepsSchema,
        harvestPeriodSchema,
        blendsPeriodSchema,
        pullingsPeriodSchema,
        seasonsSchema,
        winemakersSchema
    ]
})