import { useDatabase } from '../../models/underground/database';
import { useCallback } from 'react';
import { deleteTankInBlend } from '../../models/TanksInBlends';

export const useDeleteTankInBlend = () => {
    const database = useDatabase();

    return useCallback(async (tankInBlend) => {
        await deleteTankInBlend(database, tankInBlend);
    }, [database]);
}
