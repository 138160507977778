import { useDatabase } from '../../models/underground/database';
import { useCallback } from 'react';
import { createPullingsPeriod, endPullingsPeriod } from '../../models/PullingsPeriod';

export const useCreatePullingsPeriod = () => {
    const database = useDatabase();
    return useCallback(async (season) => {
        return await createPullingsPeriod(database, season);
    }, [database]);
}

export const useEndPullingsPeriod = () => {
    const database = useDatabase();
    return useCallback(async (pullingsPeriod) => {
        return await endPullingsPeriod(database, pullingsPeriod);
    }, [database]);
}
