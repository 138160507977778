import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { useNavigate, useOutlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BlendsList from '../components/blends/blends-list';
import BlendsPeriodProvider from '../components/blends-period/blends-period-context';
import TanksList from '../components/tanks/tanks-list';

export function BlendsPeriodPageWrapper() {
    const outlet = useOutlet();

    return (
        <BlendsPeriodProvider>
            {outlet ? outlet : <BlendsPeriodPage />}
        </BlendsPeriodProvider>
    );
}

function BlendsPeriodPage() {

    const navigate = useNavigate();
    const { t } = useTranslation();

    return (
        <Grid flexGrow={1} container spacing={2}>
            <Grid item xs={12}>
                <Stack flexGrow={1} direction='column' alignItems='flex-start'>
                    <Typography variant='h4'>{t('Blends')}</Typography>
                    <BlendsList onClick={(id) => navigate(`b/${id}`)} />
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Stack flexGrow={1} direction='column' alignItems='flex-start'>
                    <Typography variant='h4'>{t('Tanks')}</Typography>
                    <TanksList onClick={(id) => navigate(`t/${id}`)} />
                </Stack>
            </Grid>
        </Grid >

    )
}

export default BlendsPeriodPage;
