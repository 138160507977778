import { useDatabase } from '../../models/underground/database';
import { useCallback } from 'react';
import { createBlendsPeriod, endBlendsPeriod } from '../../models/BlendPeriod';

export const useCreateBlendsPeriod = () => {
    const database = useDatabase();
    return useCallback(async (season) => {
        return await createBlendsPeriod(database, season);
    }, [database]);
}

export const useEndBlendsPeriod = () => {
    const database = useDatabase();
    return useCallback(async (blendsPeriod) => {
        return await endBlendsPeriod(database, blendsPeriod);
    }, [database]);
}
