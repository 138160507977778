import React from 'react';
import { Typography } from '@mui/material';
import InlineTextField from './InlineTextField';

export default function EditableTypo({ enableEditor = false, children, typography = {}, TypoComponent = Typography, textfield = {}, TextFieldComponent = InlineTextField }) {
    if (enableEditor) {
        return React.createElement(TextFieldComponent, textfield);
    } else {
        return React.createElement(TypoComponent, typography, ...children);
    }
}
