import React, { useMemo, useState } from 'react';
import { InputAdornment, Table, TableCell, TableRow, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DerogatedTextField from '../misc/DerogatedTextField';
import { useUpdateBlendSugar } from './hooks';
import EditableTypo from '../misc/EditableTypo';

const defaultInitSugarConcentration = 0; // g/L
const defaultFinalSugarConcentration = 24; // g/L

const useSugarValue = (volume, blendInitConcentration = defaultInitSugarConcentration, blendFinalConcentration = defaultFinalSugarConcentration) => {
    const [initConcentration, setInitConcentration] = useState(blendInitConcentration || defaultInitSugarConcentration);
    const [finalConcentration, setFinalConcentration] = useState(blendFinalConcentration || defaultFinalSugarConcentration);

    const { sugarWeight, sugarVolume, derogatedInitConcentration, derogatedFinalConcentration } = useMemo(() => {
        return {
            derogatedInitConcentration: initConcentration !== defaultInitSugarConcentration,
            derogatedFinalConcentration: finalConcentration !== defaultFinalSugarConcentration,
            sugarWeight: (finalConcentration - initConcentration) * (volume * 100),
            sugarVolume: (finalConcentration - initConcentration) * (volume * 100) * (0.66 / 1000)
        }
    }, [initConcentration, finalConcentration, volume]);

    return { sugarWeight, sugarVolume, initConcentration, finalConcentration, setInitConcentration, setFinalConcentration, derogatedInitConcentration, derogatedFinalConcentration };
}

export default function BlendSugarConcentration({ blend, enableEditors = false, ...props }) {

    const { volume, sugarInitialConcentration, sugarFinalConcentration } = blend;
    const { sugarWeight, sugarVolume, initConcentration,
        finalConcentration, setInitConcentration, setFinalConcentration,
        derogatedInitConcentration, derogatedFinalConcentration } = useSugarValue(volume, sugarInitialConcentration, sugarFinalConcentration);

    const update = useUpdateBlendSugar();
    const { t } = useTranslation(['translation', 'blends']);

    return (
        <Table {...props} size='small'>
            <TableRow>
                <TableCell><Typography fontSize={12}>{t('Initial Concentration', { ns: 'blends' })}</Typography></TableCell>
                <TableCell>
                    <EditableTypo enableEditor={enableEditors} TextFieldComponent={DerogatedTextField}  textfield={{
                        derogated: derogatedInitConcentration,
                        fontSize: 12,
                        type: 'number',
                        value: initConcentration/10,
                        onChange: (ev) => {
                            const concentration = parseFloat(ev.target.value);
                            setInitConcentration(concentration * 10);
                        },
                        onBlur: (ev) => {
                            update(blend, initConcentration, finalConcentration);
                        },
                        InputProps: {
                            endAdornment: <InputAdornment position="end">{t('kg/hL')}</InputAdornment>
                        },
                    }}>{(initConcentration/10).toFixed(1)} {t('kg/hL')}</EditableTypo>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell><Typography fontSize={12}>{t('Target Concentration', { ns: 'blends' })}</Typography></TableCell>
                <TableCell>
                    <EditableTypo enableEditor={enableEditors} TextFieldComponent={DerogatedTextField} textfield={{
                        derogated: derogatedFinalConcentration,
                        fontSize: 12,
                        type: 'number',
                        value: finalConcentration/10,
                        onChange: (ev) => {
                            const concentration = parseFloat(ev.target.value);
                            setFinalConcentration(concentration * 10);
                        },
                        onBlur: (ev) => {
                            update(blend, initConcentration, finalConcentration);
                        },
                        InputProps: {
                            endAdornment: <InputAdornment position="end">{t('kg/hL')}</InputAdornment>
                        },
                    }}>{(finalConcentration/10).toFixed(1)} {t('kg/hL')}</EditableTypo>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell><Typography fontSize={12}>{t('Sugar Quantity', { ns: 'blends' })}</Typography></TableCell>
                <TableCell><Typography fontSize={12}>{`${sugarWeight / 1000} ${t('kg')}`}</Typography></TableCell>
            </TableRow>
            <TableRow>
                <TableCell><Typography fontSize={12}>{t('Increase in volume', { ns: 'blends' })}</Typography></TableCell>
                <TableCell><Typography fontSize={12}>{`${(sugarVolume / 100).toFixed(2)} ${t('hL')}`}</Typography></TableCell>
            </TableRow>
            <TableRow>
                <TableCell><Typography fontSize={12}>{t('Volume after sugaring', { ns: 'blends' })}</Typography></TableCell>
                <TableCell><Typography fontSize={12}>{`${(volume + (sugarVolume / 100)).toFixed(2)} ${t('hL')}`}</Typography></TableCell>
            </TableRow>
        </Table>
    )
}
