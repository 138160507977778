import withObservables from '@nozbe/with-observables'
import { compose } from 'recompose'
import { withBlendsPeriodInCtx } from './blends-period-context'

export const withBlendsInBlendsPeriodList = compose(
    withBlendsPeriodInCtx,
    withObservables(['blendsPeriod'], ({ blendsPeriod }) => ({
        blendsPeriod,
        blends: blendsPeriod.blends,
    }))
);

export const withTanksInBlendsPeriodList = compose(
    withBlendsPeriodInCtx,
    withObservables(['blendsPeriod'], ({ blendsPeriod }) => ({
        blendsPeriod,
        tanks: blendsPeriod.tanks,
    }))
);