import React from 'react';
import { Timeline, timelineOppositeContentClasses } from '@mui/lab';
import { useSeason } from './seasons-context';
import HarvestPeriodInTimeline from '../harvest-period/harvest-period-in-timeline';
import BlendsPeriodInTimeline from '../blends-period/blends-period-in-timeline';
import PullingsPeriodInTimeline from '../pullings-period/pullings-period-in-timeline';

export default function SeasonTimeline() {

    const season = useSeason();

    return (
        <Timeline position='right' sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0,
            },
        }}>
            <HarvestPeriodInTimeline season={season} />
            <BlendsPeriodInTimeline season={season} />
            <PullingsPeriodInTimeline season={season} />
        </Timeline>
    );
}