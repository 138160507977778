import withObservables from '@nozbe/with-observables'
import { withDatabase } from '../../models/underground/database';
import { compose } from 'recompose'
import { winemakersTableName } from '../../models/Winemaker';
import { Q } from '@nozbe/watermelondb'

export const withWinemakersList = compose(
    withDatabase,
    withObservables([], ({ database }) => ({
        winemakers: database.get(winemakersTableName).query(),
    }))
);

export const withWinemaker = compose(
    withDatabase,
    withObservables([], ({ database, winemaker_id }) => ({
        winemaker: database.get(winemakersTableName).findAndObserve(winemaker_id)
    })),
);

export const withWinemakerSeasonsList = compose(
    withWinemaker,
    withObservables(['winemaker'], ({ winemaker }) => ({
        winemaker,
        seasons: winemaker.seasons.extend(Q.sortBy('created_at', 'desc')),
    }))
);
