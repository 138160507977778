import React, { Fragment } from 'react';
import _, { min } from 'lodash';
import { Box, Card, Typography, useTheme } from '@mui/material';
import { withObservedTanksInBlendsInTank } from './observables';

import './swell.css';
import { useTranslation } from 'react-i18next';
import { VARIETIES } from '../../models/Varieties';

export const computeRemainingTankVolumeLiter = (tank, tanksInBlend) => {
    return _.reduce(tanksInBlend, (remainingVolume, tankInBlend) => remainingVolume - tankInBlend.volume, tank.volume);
}

export const useComputeRemainingTankVolumeLiter = (tank, tanksInBlend) => {
    return computeRemainingTankVolumeLiter(tank, tanksInBlend);
}

export const useComputeRemainingTankVolumePercentage = (tank, tanksInBlend) => {
    const remainingVolumeLiter = useComputeRemainingTankVolumeLiter(tank, tanksInBlend);
    return [remainingVolumeLiter / tank.volume * 100, remainingVolumeLiter];
}

function TankRemainingVolumeValueInternal({ tank, tanksInBlend, ...props }) {
    const remainingVolumeLiter = useComputeRemainingTankVolumeLiter(tank, tanksInBlend);

    return (
        <Typography {...props}>{remainingVolumeLiter.toFixed(0)} hL</Typography>
    )
}

export const TankRemainingVolumeValue = withObservedTanksInBlendsInTank(TankRemainingVolumeValueInternal);

const getFirstVariety = (tank) => {
    if(tank.varieties) {
        const varieties = tank.varieties.split(',');
        if(varieties.length > 1) return undefined;
        else return _.find(VARIETIES, v => v.id === varieties[0]);
    }
    return undefined;
}

function TankRemainingVolumeGraphInternal({ tank, tanksInBlend, height, minHeight = '100px', width, maxWidth = '75px' }) {

    const theme = useTheme();
    const { t } = useTranslation('tanks');
    let [percentage, liter] = useComputeRemainingTankVolumePercentage(tank, tanksInBlend);

    const firstVariety = getFirstVariety(tank);

    percentage = percentage < 0 ? 0 : min([percentage, 100]);
    const backgroundColor = firstVariety ? firstVariety.color : theme.palette.primary[theme.palette.mode];
    const borderColor = liter <= 0 ? liter === 0 ? theme.palette.success.light : 'orangered' : theme.palette.grey['400'];
    const textColor = liter < 0 ? 'orangered' : theme.palette.getContrastText(percentage === 0 ? theme.palette.background.paper : backgroundColor);
    const wineHeight = `${percentage}%`;
    const empty = percentage === 0;

    const content = (
        <Fragment>
            <Typography fontSize={12} color={textColor}>{liter.toFixed(0)} {t('hL')}</Typography>
        </Fragment>
    )

    return (
        <Card sx={{
            height: height || minHeight, minHeight, width: width || maxWidth, maxWidth,
            borderRadius: '50% / 5%', border: 1, borderColor,
            p: 'calc(5% + 5px) 5px calc(5% + 5px)  5px',
            display: 'flex', flexDirection: 'column'
        }}
            elevation={2}
        >
            <Box sx={{ width: '100%' }} flexGrow={1} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                {empty && content}
            </Box>
            <Box sx={{
                overflow: 'hidden',
                '& svg': {
                    animationName: 'swell',
                    animationDuration: '2s',
                    animationFillMode: 'forwards',
                    animationIterationCount: 'infinite',
                    animationTimingFunction: 'linear',
                    fill: backgroundColor,
                    width: '200%',
                    display: 'block',
                    mb: '-2px',
                },
                width: '100%'
            }}>
                <svg viewBox="0 0 12960 1120">
                    <path d="M9720,320C8100,320,8100,0,6480,0S4860,320,3240,320,1620,0,0,0V1120H12960V0C11340,0,11340,320,9720,320Z">
                        <animate
                            dur="5s"
                            repeatCount="indefinite"
                            attributeName="d"
                            values="
                                M9720,320C8100,320,8100,0,6480,0S4860,320,3240,320,1620,0,0,0V1120H12960V0C11340,0,11340,320,9720,320Z;
                                M9720,0C8100,0,8100,319,6480,319S4860,0,3240,0,1620,320,0,320v800H12960V320C11340,320,11340,0,9720,0Z;
                                M9720,320C8100,320,8100,0,6480,0S4860,320,3240,320,1620,0,0,0V1120H12960V0C11340,0,11340,320,9720,320Z
                                "/>
                    </path>
                </svg>
            </Box>
            <Box sx={{ width: '100%', backgroundColor: backgroundColor, height: wineHeight }} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
                {!empty && content}
            </Box>
        </Card>
    )
}

const TankRemainingVolumeGraph = withObservedTanksInBlendsInTank(TankRemainingVolumeGraphInternal);

export default TankRemainingVolumeGraph;