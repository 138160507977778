import React from 'react';
import { Button, Box, Stack, Typography } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useCreateSeason } from './hooks';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export default function CreateFirstSeason({ winemaker }) {

    const { t } = useTranslation(['translation', 'seasons']);
    const createSeason = useCreateSeason();


    return (
        <Box flexGrow={1} display='flex' alignItems='center' justifyContent='center'>
            <Formik
                initialValues={{
                    name: `${moment().get('year')}-${moment().add(1, 'year').get('year')}`,
                    winemaker
                }}
                onSubmit={async (season, { setSubmitting }) => {
                    await createSeason(season);
                    setSubmitting(false);
                }}
            >
                {props => (
                    <Stack sx={{ width: '500px' }} spacing={2} alignItems='center'>
                        <Typography variant='h4'>
                            {t('Create your first season', { ns: 'seasons' })}
                        </Typography>
                        <Field
                            fullWidth
                            component={TextField}
                            label={t('Name', { ns: 'seasons' })}
                            name="name"
                            variant="outlined"
                        />
                        <Button fullWidth color='secondary' variant='outlined' onClick={props.submitForm}>
                            {t('Create')}
                        </Button>
                    </Stack>
                )}
            </Formik>
        </Box >
    )
}
