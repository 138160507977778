import { useDatabase } from '../../models/underground/database';
import { useCallback } from 'react';
import { createBlend, deleteBlend, updateBlend } from '../../models/Blends';
import { addTankToBlend } from '../../models/TanksInBlends';
import _ from 'lodash';

export const useCreateBlend = () => {
    const database = useDatabase();

    return useCallback(async (blend) => {
        await createBlend(database, blend);
    }, [database]);
}

export const useDeleteBlend = () => {
    const database = useDatabase();

    return useCallback(async (blend) => {
        await deleteBlend(database, blend);
    }, [database]);
}

export const useUpdateBlendVolume = () => {
    const database = useDatabase();

    return useCallback(async (blend, volume) => {
        const updater = () => {
            if (_.isFinite(volume) && volume > 0) {
                blend.volume = volume;
            }
        }
        await updateBlend(database, blend, updater);
    }, [database]);
}

export const useUpdateBlendSugar = () => {
    const database = useDatabase();

    return useCallback(async (blend, init, final) => {
        const updater = () => {
            if (_.isFinite(init)) {
                blend.sugarInitialConcentration = init;
            }
            if (_.isFinite(final)) {
                blend.sugarFinalConcentration = final;
            }
        }
        await updateBlend(database, blend, updater);
    }, [database]);
}

export const useAddTankToBlend = () => {
    const database = useDatabase();

    return useCallback(async (blend, { tank_id, volume }) => {
        await addTankToBlend(database, { blend_id: blend.id, tank_id, volume });
    }, [database]);
}
