import React from 'react';
import _ from 'lodash';
import { Box, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { withObservedTanksInBlendsInTank } from './observables';
import TankRemainingVolumeGraph, { useComputeRemainingTankVolumeLiter } from './tank-remaining-volume-graph';
import { useTranslation } from 'react-i18next';
import { withTanksInBlendsPeriodList } from '../blends-period/observables';


function TanksSelectItemInternal({ tank, tanksInBlend }) {

  const remaingVolume = useComputeRemainingTankVolumeLiter(tank, tanksInBlend);
  const { t } = useTranslation();

  return (
    <Stack width={'100%'} direction='row' justifyContent={'space-between'} alignItems={'center'}>
      <Box>
        <Typography fontSize={14}>{tank.name}</Typography>
        <Typography fontSize={10}>{remaingVolume} {t('hL')} / {tank.volume} {t('hL')}</Typography>
      </Box>
      <Box>
        <TankRemainingVolumeGraph tank={tank} minHeight='75px' width='100px' />
      </Box>
    </Stack>

  )
}
export const TankSelectItem = withObservedTanksInBlendsInTank(TanksSelectItemInternal)

function TanksSelectInternal({ tanks, value, onChange, ...props }) {

  return (
    <TextField
      {...props}
      select
      defaultValue=''
      value={value}
      onChange={onChange}
    >
      {_.map(tanks, (tank) => (
        <MenuItem key={tank.id} value={tank.id}>
          <TankSelectItem tank={tank} />
        </MenuItem>
      ))}
    </TextField>
  )
}

const TanksSelect = withTanksInBlendsPeriodList(TanksSelectInternal);

export default TanksSelect;
