import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import moment from 'moment';
import { useCreatePullingsPeriod, useEndPullingsPeriod } from './hooks';

import { withPullingsPeriodInSeason } from '../seasons/observables';
import SeasonTimelineItem from '../seasons/season-timeline-item';

function PullingsPeriodInTimelineInternal({ season, pullingsPeriod }) {

    const { t } = useTranslation(['translation', 'seasons']);

    const createPullingsPeriod = useCreatePullingsPeriod();
    const endPullingsPeriod = useEndPullingsPeriod();

    const handleClickStart = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        createPullingsPeriod(season).catch(e => console.error(e));
    }

    const handleClickFinish = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        endPullingsPeriod(pullingsPeriod).catch(e => console.error(e));
    }

    const status = pullingsPeriod ? pullingsPeriod.endedAt ? 'done' : 'running' : 'idle';
    const date = pullingsPeriod ? moment(pullingsPeriod.endedAt || pullingsPeriod.createdAt).format('MM/YYYY') : 'Awaiting';
    const title = t('Pulling');


    return (
        <SeasonTimelineItem status={status} date={date} title={title}>
            <Fragment>
                {!pullingsPeriod && (
                    <Button variant='outlined' color='secondary' onClick={handleClickStart}>{t('Start')}</Button>
                )}
                {pullingsPeriod && !pullingsPeriod.endedAt && (
                    <Button variant='outlined' color='secondary' onClick={handleClickFinish}>{t('Finished')}</Button>
                )}
            </Fragment>
        </SeasonTimelineItem>
    );
}

const PullingsPeriodInTimeline = withPullingsPeriodInSeason(PullingsPeriodInTimelineInternal);

export default PullingsPeriodInTimeline