import { tableSchema, Model } from '@nozbe/watermelondb'
import { date, relation } from '@nozbe/watermelondb/decorators'
import moment from 'moment';

export const harvestPeriodTableName = 'harvestperiod';

export const harvestPeriodSchema = tableSchema({
    name: harvestPeriodTableName,
    columns: [
        { name: 'season_id', type: 'string' },
        { name: 'ended_at', type: 'number', isOptional: true },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
    ]
});

export default class HarvestPeriod extends Model {
    static table = harvestPeriodTableName
    static associations = {
        seasons: { type: 'belongs_to', key: 'season_id' },
    }

    @relation('seasons', 'season_id') season
    @date('ended_at') endedAt
    @date('created_at') createdAt
    @date('updated_at') updatedAt
}

export async function createHarvestPeriod(database, season) {
    await database.write(async () => {
        const newHarvestPeriod = await database
            .get(harvestPeriodTableName)
            .create(p => {
                p.season.set(season);
            });
        await season.update(() => {
            season.harvestPeriod.set(newHarvestPeriod);
        });
        return newHarvestPeriod;
    });
}

export async function endHarvestPeriod(database, harvestPeriod) {
    await database.write(async () => {
        await harvestPeriod.update((p) => {
            p.endedAt = moment().valueOf();
        });
    });
}
