import * as React from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import _ from 'lodash';
import CircleIcon from '@mui/icons-material/Circle';
import { GROUP_CHAMPAGNE, varietiesFor } from '../../models/Varieties';
import { useTranslation } from 'react-i18next';


export default function VarietiesToChip({ varieties, group = GROUP_CHAMPAGNE }) {

    const VARIETIES = varietiesFor(group);
    const { t } = useTranslation('varieties');

    varieties = varieties ? varieties.split(',') : [];

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {_.chain(varieties)
                .map((id) => _.find(VARIETIES, v => v.id === id))
                .compact()
                .map(({ name,id, color }) => {
                    return (
                        <Chip size='small' icon={<CircleIcon sx={{ fill: color }} />} key={id} label={t(name)} />
                    )
                }).value()
            }
        </Box>
    )
}
