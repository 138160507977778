import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Box, Drawer, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme, Stack, Typography } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ResetDatabaseButton from './misc/database/ResetDatabaseButton';

const SidebarContext = createContext({
    isOpen: false,
    open: () => console.error('early call to SidebarContext.open()'),
    close: () => console.error('early call to SidebarContext.close()')
});

export const useSidebarContext = () => useContext(SidebarContext);

const drawerWidth = 200;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

export function SidebarElement({ text, href, icon }) {

    const { isOpen } = useSidebarContext();
    const navigate = useNavigate();
    const handleClick = (ev) => {
        if (href) {
            navigate(href);
        }
    }

    return (
        <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton onClick={href ? handleClick : undefined} sx={{ minHeight: 48, justifyContent: isOpen ? 'initial' : 'center', px: 2.5, }}>
                {icon && <ListItemIcon sx={{ minWidth: 0, mr: isOpen ? 3 : 'auto', justifyContent: 'center' }} >
                    {icon}
                </ListItemIcon>}
                <ListItemText primary={text} sx={{ opacity: isOpen ? 1 : 0 }} />
            </ListItemButton>
        </ListItem>
    )
}

export default function SideBar({ children, defaultOpen = false }) {

    const [open, setOpen] = useState(defaultOpen);
    const theme = useTheme();

    const toggleDrawer = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setOpen(opn => !opn);
    }

    const style = open ? {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    } : {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }

    return (
        <Drawer sx={{
            ...style, flexShrink: 0,
            whiteSpace: 'nowrap',
            boxSizing: 'border-box',
        }} variant="permanent" open={open}>
            <Box sx={{ ...theme.mixins.toolbar }} ></Box>
            <Box textAlign={'right'} paddingRight={1}>
                <IconButton onClick={toggleDrawer}>
                    {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </Box>
            <Divider />
            <SidebarContext.Provider value={{ isOpen: open, open: () => setOpen(true), close: () => setOpen(false) }}>
                {children}
            </SidebarContext.Provider>
            <Box sx={{ flexGrow: 1 }}></Box>
            <Box sx={{ py: 1 }}>
                <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'>
                    <Typography variant='body2' fontSize={12}>{process.env.REACT_APP_VERSION}</Typography>
                    {open && <ResetDatabaseButton size='small' color='error' />}
                </Stack>
            </Box>
        </Drawer>
    );
}