import React from 'react';
import TankCard from '../components/tanks/tank-card';
import { useParams } from 'react-router-dom';
import { withTank } from '../components/tanks/observables';

const TankDetails = withTank(TankCard)

function TankPage() {
    const { tank_id } = useParams();

    return (
        <TankDetails tank_id={tank_id} sx={{ flexGrow: 1 }} hideViewAction />
    );
}

export default TankPage;
