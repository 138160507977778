import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import PersonalCalendar from '../calendar/PersonalCalendar';

function PersonnalDashboard() {

    return (
        <Stack direction='column' flexGrow={1} alignItems='center' justifyContent={'center'} spacing={4}>
            <Typography>Welcome</Typography>
            <Box flexGrow={1} width={'100%'}>
                <PersonalCalendar />
            </Box>
        </Stack>
    )
}

export default PersonnalDashboard;
