import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { useCallback, useState } from 'react';
import _ from 'lodash';
import { createId } from '@paralleldrive/cuid2';

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar);


const useEventsHandlers = () => {
    const [events, setEvents] = useState([]);

    const addEvent = useCallback(({ start, end, title }) => {
        setEvents(events => {
            events = _.clone(events);
            events.push({ id: createId(), start, end, title });
            return events;
        });
    }, [setEvents]);

    const removeEvent = useCallback((data) => {
        return null;
    }, []);

    const onEventResize = useCallback((data) => {
        const { start, end, isAllDay, event } = data;
        event.start = start;
        event.end = end;
        event.allDay = isAllDay
        setEvents(events => [...events]);
    }, []);

    const onEventDrop = useCallback((data) => {
        const { start, end, isAllDay, event } = data;
        event.start = start;
        event.end = end;
        event.allDay = isAllDay
        setEvents(events => [...events]);
    }, []);

    return { events, addEvent, removeEvent, onEventResize, onEventDrop };
}



const PersonalCalendar = (props) => {

    const { events, onEventDrop, onEventResize, addEvent } = useEventsHandlers();

    const onSelectSlot = (data) => {
        const { start, end } = data;
        addEvent({ start, end, title: 'New Event' });
    };

    return (
        <DnDCalendar
            localizer={localizer}
            events={events}
            popup
            onEventDrop={onEventDrop}
            onEventResize={onEventResize}
            onSelectSlot={onSelectSlot}
            selectable
            startAccessor="start"
            endAccessor="end"
        />
    )
};

export default PersonalCalendar;