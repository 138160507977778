import React from 'react';
import { TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import PauseIcon from '@mui/icons-material/Pause';
import AvTimerIcon from '@mui/icons-material/AvTimer';

export default function SeasonTimelineItem({ title, date, status = 'awaiting', children, icon, color, bgcolor, ...props }) {

    const color_ = color || status === 'idle' ? undefined : status === 'done' ? 'primary' : 'secondary';
    const bgcolor_ = bgcolor || status === 'idle' ? undefined : status === 'done' ? 'primary.main' : 'secondary.main';
    const icon_ = icon || status === 'idle' ? <PauseIcon /> : status === 'done' ? <DoneIcon /> : <AvTimerIcon />;

    return (
        <TimelineItem>
            <TimelineOppositeContent sx={{ m: 'auto 0' }} color="textSecondary">
                {date}
            </TimelineOppositeContent>
            <TimelineSeparator>
                <TimelineDot color={color_}>
                    {icon_}
                </TimelineDot>
                <TimelineConnector sx={{ bgcolor: bgcolor_ }} />
            </TimelineSeparator>
            <TimelineContent sx={{ mb: 2 }}>
                <Typography>{title}</Typography>
                <Box sx={{ minHeight: '200px' }} {...props} >
                    {children}
                </Box>
            </TimelineContent>
        </TimelineItem>
    );
}
