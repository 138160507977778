import { tableSchema, Model } from '@nozbe/watermelondb'
import { children, date, field, } from '@nozbe/watermelondb/decorators'

export const leavenStepsTableName = 'leavensteps';
export const leavensTableName = 'leavens';

export const leavensSchema = tableSchema({
    name: leavensTableName,
    columns: [
        { name: 'percentage', type: 'number' }, // table the id is related to.
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
    ]
});

export default class Leavens extends Model {
    static table = leavensTableName
    static associations = {
        [leavenStepsTableName]: { type: 'has_many', foreign_key: 'leaven' }
    }

    @field('percentage') percentage
    @children(leavenStepsTableName) steps

    @date('created_at') createdAt
    @date('updated_at') updatedAt
}
