import { Typography } from '@mui/material';
import _ from 'lodash';
import React, { createContext, useContext, useMemo } from 'react';
import { withContext } from '../misc/withContext';
import { withWinemakerSeasonsList } from '../winemakers/observables';
import CreateFirstSeason from './create-first-season';

const SeasonContext = createContext({
    season: null,
    winemaker: null,
});

export const useSeason = () => useContext(SeasonContext).season;
export const useWinemaker = () => useContext(SeasonContext).winemaker;

export const withSeasonCtx = withContext(SeasonContext, ({season}) => ({season}));
export const withWinemakerCtx = withContext(SeasonContext, ({winemaker}) => ({winemaker}));

function SeasonProviderInternal({ season_id, seasons, winemaker, children }) {

    const season = useMemo(() => {

        if (!season_id || season_id === 'lastest') {
            return seasons[0] || null;
        } else {
            return _.find(seasons, s => s.id === season_id);
        }

    }, [season_id, seasons]);


    if (season === null) {
        if (seasons.length === 0) {
            return <CreateFirstSeason winemaker={winemaker} />
        } else {
            return <Typography>Could not find the season.</Typography>
        }
    }

    return (
        <SeasonContext.Provider value={{ season, winemaker }}>
            {children}
        </SeasonContext.Provider>
    )

}

const SeasonProvider = withWinemakerSeasonsList(SeasonProviderInternal);

export default SeasonProvider;
