import React, { Fragment, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Field, Formik, useField } from 'formik';
import { TextField } from 'formik-mui';
import { useCreateTank } from './hooks';
import VarietiesMultipleSelect from '../varieties/varieties-select';
import FractionSelect from '../fractions/fraction-select';
import { useTranslation } from 'react-i18next';
import { useWinemaker } from '../seasons/seasons-context';
import { useBlendsPeriod } from '../blends-period/blends-period-context';

export const VarietiesField = ({ name, ...props }) => {
    const [field] = useField(name);
    return <VarietiesMultipleSelect {...props} name={name} {...field} />
}

export const FractionsField = ({ name, ...props }) => {
    const [field] = useField(name);
    return <FractionSelect {...props} name={name} {...field} />
}

export function TanksCreateModal({ open, onClose }) {

    const winemaker = useWinemaker();
    const blendsPeriod = useBlendsPeriod();

    const { t } = useTranslation(['translation', 'tanks']);
    const createTank = useCreateTank();

    return (
        <Dialog open={open} onClose={onClose}>
            <Formik
                initialValues={{
                    name: '',
                    description: '',
                    varieties: '',
                    fraction: '',
                    year: new Date().getFullYear(),
                    volume: 1,
                    winemaker,
                    blendsPeriod,
                }}
                onSubmit={async (tank, { setSubmitting }) => {
                    await createTank(tank);
                    setSubmitting(false);
                    onClose();
                }}
            >
                {props => (
                    <Fragment>
                        <DialogTitle>
                            {t('Add a Tank', { ns: 'tanks' })}
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} sx={{ p: 2 }}>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        component={TextField}
                                        label={t('Name', { ns: 'tanks' })}
                                        name="name"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        component={TextField}
                                        label={t('Description', { ns: 'tanks' })}
                                        name="description"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <VarietiesField
                                        fullWidth
                                        label={t('Varieties', { ns: 'tanks' })}
                                        name="varieties"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FractionsField
                                        fullWidth
                                        label={t('Fraction', { ns: 'tanks' })}
                                        name="fraction"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        component={TextField}
                                        label={t('Year', { ns: 'tanks' })}
                                        name="year"
                                        type="number"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        component={TextField}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">{t('hL')}</InputAdornment>
                                        }}
                                        inputProps={{
                                            min: 0,
                                        }}
                                        label={t('Volume', { ns: 'tanks' })}
                                        name="volume"
                                        type="number"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button color='inherit' onClick={onClose}>
                                {t('Cancel')}
                            </Button>
                            <Button color='primary' onClick={props.submitForm}>
                                {t('Add')}
                            </Button>
                        </DialogActions>
                    </Fragment>
                )}
            </Formik>
        </Dialog >
    )
}

export default function TanksCreateButton() {

    const [open, setOpen] = useState(false);

    const handleClickOpen = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(true);
    };

    const handleClose = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(false);
    };

    return (
        <Box sx={{ p: 2 }}>
            <IconButton onClick={handleClickOpen}>
                <AddIcon />
            </IconButton>
            <TanksCreateModal open={open} onClose={handleClose} />
        </Box>
    )
}
