import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";


function ToolsList(props) {
    const { t } = useTranslation();
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant='h4'>{t('Tools')}</Typography>
            </Grid>
            <Grid container item xs={12}>

            </Grid>
        </Grid>
    )
}

export default ToolsList;