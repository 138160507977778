import { tableSchema, Model, Q } from '@nozbe/watermelondb'
import { children, date, field, lazy, relation, text } from '@nozbe/watermelondb/decorators'
import { tanksInBlendTableName } from './TanksInBlends';
import { winemakersTableName } from './Winemaker';

export const blendsTableName = 'blends';

export const blendsSchema = tableSchema({
    name: blendsTableName,
    columns: [
        { name: 'name', type: 'string' }, // nom donné par le récoltant.
        { name: 'description', type: 'string', isOptional: true }, // informations supplémentaires optionnelles.
        { name: 'volume', type: 'number' }, // volume en hectolitres.
        { name: 'sugar_initial_concentration', type: 'number' }, // g/L
        { name: 'sugar_final_concentration', type: 'number' }, // g/L
        { name: 'winemaker_id', type: 'string' },
        { name: 'blends_period_id', type: 'string' },
        { name: 'date', type: 'number' },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
    ]
});

export default class Blend extends Model {
    static table = blendsTableName
    static associations = {
        [winemakersTableName]: { type: 'belongs_to', key: 'winemaker_id' },
        blendsperiod: { type: 'belongs_to', key: 'blends_period_id' },
        [tanksInBlendTableName]: { type: 'has_many', foreignKey: 'blend_id' }
    }

    @text('name') name
    @text('description') description
    @field('volume') volume
    @field('sugar_initial_concentration') sugarInitialConcentration
    @field('sugar_final_concentration') sugarFinalConcentration
    @date('date') date
    @relation(winemakersTableName, 'winemaker_id') winemaker
    @relation('blendsperiod', 'blends_period_id') blendsPeriod
    @children(tanksInBlendTableName) tanksInBlend

    @lazy tanksInUse = this.collections
        .get('tanks')
        .query(Q.on(tanksInBlendTableName, 'blend_id', this.id));

    @date('created_at') createdAt
    @date('updated_at') updatedAt
}

export async function createBlend(database, blend) {
    await database.write(async () => {
        const newBlend = await database.get(blendsTableName).create(b => {
            b.name = blend.name;
            b.description = blend.description;
            b.volume = blend.volume;
            b.winemaker.set(blend.winemaker);
            b.blendsPeriod.set(blend.blendsPeriod);
        })
        return newBlend;
    });
}

export async function updateBlend(database, blend, updater) {
    await database.write(async () => {
        await blend.update(updater);
    });
}

export async function deleteBlend(database, blend) {
    await database.write(async () => {
        await blend.markAsDeleted();
    });
}
