import LokiJSAdapter from '@nozbe/watermelondb/adapters/lokijs'
import schema from './schemas'
import migrations from './migrations'

// First, create the adapter to the underlying database:
export const adapter = new LokiJSAdapter({
    schema,
    migrations,
    useWebWorker: false,
    useIncrementalIndexedDB: true,
    dbName: 'oenology',

    onQuotaExceededError: (error) => {
        console.error({ message: 'no more space.', error });

    },
    onSetUpError: (error) => {
        console.error({ message: 'failed to load database.', error });
    },
    extraIncrementalIDBOptions: {
        onDidOverwrite: () => {
        },
        onversionchange: () => {
        },
    }
});
