import { useDatabase } from '../../models/underground/database';
import { useCallback } from 'react';
import { createSeason } from '../../models/Seasons';

export const useCreateSeason = () => {
    const database = useDatabase();

    return useCallback(async (season) => {
        await createSeason(database, season);
    }, [database]);
}
