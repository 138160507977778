import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useOutlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PullingsPeriodProvider from '../components/pullings-period/pullings-period-context';

export function PullingsPeriodPageWrapper() {
    const outlet = useOutlet();

    return (
        <PullingsPeriodProvider>
            {outlet ? outlet : <PullingsPeriodPage />}
        </PullingsPeriodProvider>
    );
}

function PullingsPeriodPage() {
    const { t } = useTranslation();

    return (
        <Stack flexGrow={1} direction='column' alignItems='flex-start'>
            <Typography variant='h4'>{t('Pullings')}</Typography>
        </Stack>
    )
}

export default PullingsPeriodPage;
