import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDeleteBlend } from './hooks';
import BlendCard from './blend-card';
import { useTranslation } from 'react-i18next';

export function BlendDeleteModal({ blend, open, onClose }) {

    const { t } = useTranslation(['translation', 'blends']);
    const deleteBlend = useDeleteBlend();
    const handleClick = async (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        await deleteBlend(blend);
        onClose();
    }
    const [tanks, setTanks] = useState(null);

    useEffect(() => {
        blend.tanksInUse.fetch().then(tanks => {
            setTanks(tanks);
        });
        // eslint-disable-next-line
    }, []);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {t('Delete the blend ?', { ns: 'blends' })}
            </DialogTitle>
            <DialogContent>
                <BlendCard blend={blend} hideViewAction hideRemoveAction hideSugar />
                {tanks !== null && tanks.length > 0 && (
                    <Alert sx={{ mt: 2 }} severity="warning">{t('Blend uses tanks', { ns: 'blends' })}</Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button color='inherit' onClick={onClose}>
                    {t('Cancel')}
                </Button>
                <Button disabled={tanks === null || tanks.length > 0} color='primary' onClick={handleClick}>
                    {t('Confirm')}
                </Button>
            </DialogActions>
        </Dialog >
    )
}

export default function BlendDeleteButton({ blend }) {

    const [open, setOpen] = useState(false);

    const handleClickOpen = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(true);
    };

    const handleClose = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(false);
    };

    return (
        <Box sx={{ p: 2 }}>
            <IconButton onClick={handleClickOpen}>
                <DeleteIcon />
            </IconButton>
            <BlendDeleteModal open={open} onClose={handleClose} blend={blend} />
        </Box>
    )
}
