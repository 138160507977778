import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import moment from 'moment';
import { useCreateHarvestPeriod, useEndHarvestPeriod } from './hooks';

import { withHarvestPeriodInSeason } from '../seasons/observables';
import SeasonTimelineItem from '../seasons/season-timeline-item';

function HarvestPeriodInTimelineInternal({ season, harvestPeriod }) {

    const { t } = useTranslation(['translation', 'seasons']);

    const createHarvestPeriod = useCreateHarvestPeriod();
    const endHarvestPeriod = useEndHarvestPeriod();

    const handleClickStart = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        createHarvestPeriod(season).catch(e => console.error(e));
    }

    const handleClickFinish = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();

        endHarvestPeriod(harvestPeriod).catch(e => console.error(e));
    }

    const status = harvestPeriod ? harvestPeriod.endedAt ? 'done' : 'running' : 'idle';
    const date = harvestPeriod ? moment(harvestPeriod.endedAt || harvestPeriod.createdAt).format('MM/YYYY') : 'Awaiting';
    const title = t('Harvest');

    return (
        <SeasonTimelineItem status={status} date={date} title={title}>
            <Fragment>
                {!harvestPeriod && (
                    <Button variant='outlined' color='secondary' onClick={handleClickStart}>{t('Start')}</Button>
                )}
                {harvestPeriod && !harvestPeriod.endedAt && (
                    <Button variant='outlined' color='secondary' onClick={handleClickFinish}>{t('Finished')}</Button>
                )}
            </Fragment>
        </SeasonTimelineItem>
    );
}

const HarvestPeriodInTimeline = withHarvestPeriodInSeason(HarvestPeriodInTimelineInternal);

export default HarvestPeriodInTimeline