import { Button, Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { withWinemakersList } from './observables';
import { useTranslation } from 'react-i18next';

export function WinemakersListItem({ winemaker: { id, name, description } }) {

    const { t } = useTranslation()
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/w/${id}`);
    }

    return (

        <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }} onClick={handleClick}>
            <CardContent sx={{ flexGrow: 1 }}>
                <Typography >{name}</Typography>
                <Typography fontSize={12}>{description}</Typography>
            </CardContent>
            <CardActions>
                <Button size="small" onClick={handleClick} >{t('View')}</Button>
            </CardActions>
        </Card>
    )
}

export function WinemakersList({ winemakers }) {

    return (
        <Grid container spacing={2}>
            {_.map(winemakers, (winemaker) => (
                <Grid key={winemaker.id} item xs={6} sm={2} >
                    <WinemakersListItem winemaker={winemaker} />
                </Grid>
            ))}
        </Grid>
    )
}

export default withWinemakersList(WinemakersList);
