import React, { createContext, useContext, } from 'react';
import { withContext } from '../misc/withContext';
import { withPullingsPeriodInSeason } from '../seasons/observables';
import PullingsPeriodCreate from './pullings-period-create';

const PullingsPeriodContext = createContext({
    pullingsPeriod: null
});

export const usePullingsPeriod = () => useContext(PullingsPeriodContext);
export const withPullingsPeriodInCtx = withContext(PullingsPeriodContext, ({ pullingsPeriod }) => ({
    pullingsPeriod,
}));

function PullingsPeriodProviderInternal({ season, pullingsPeriod, children }) {

    if (pullingsPeriod === null) {
        return <PullingsPeriodCreate season={season} />
    }

    return (
        <PullingsPeriodContext.Provider value={{ pullingsPeriod }}>
            {children}
        </PullingsPeriodContext.Provider>
    )

}

const PullingsPeriodProvider = withPullingsPeriodInSeason(PullingsPeriodProviderInternal);

export default PullingsPeriodProvider;
