import React from 'react';
import WinemakersList from '../components/winemakers/winemakers-grid';
import WinemakerCreateButton from '../components/winemakers/winemakers-create-button';
import { Stack } from '@mui/material';

function Winemakers() {
    return (
        <Stack direction='column' flexGrow={1} alignItems='center' spacing={4}>
            <WinemakersList />
            <WinemakerCreateButton />
        </Stack>
    );
}

export default Winemakers;
