import React from 'react';
import DatabaseProvider from './models/underground/database';
import { CssBaseline, ThemeProvider } from '@mui/material';
import AppRouter from './routes';
import { theme } from './theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <DatabaseProvider>
        <CssBaseline />
        <AppRouter />
      </DatabaseProvider>
    </ThemeProvider>
  );
}

export default App;
