import { tableSchema } from '@nozbe/watermelondb'
import { Model } from '@nozbe/watermelondb'
import { children, date, text } from '@nozbe/watermelondb/decorators'

export const winemakersTableName = 'winemakers';

export const winemakersSchema = tableSchema({
    name: winemakersTableName,
    columns: [
        { name: 'name', type: 'string' }, // nom du récoltant.
        { name: 'description', type: 'string', isOptional: true },
        { name: 'created_at', type: 'number' },
        { name: 'updated_at', type: 'number' },
    ]
});

export default class Winemaker extends Model {
    static table = winemakersTableName
    static associations = {
        tanks: { type: 'has_many', foreignKey: 'winemaker_id' },
        blends: { type: 'has_many', foreignKey: 'winemaker_id' },
        seasons: { type: 'has_many', foreignKey: 'winemaker_id' },
    }

    @text('name') name
    @text('description') description
    @children('tanks') tanks
    @children('blends') blends
    @children('seasons') seasons

    @date('created_at') createdAt
    @date('updated_at') updatedAt
}

export async function createWinemaker(database, winemaker) {
    await database.write(async () => {
        const newWinemaker = await database.get(winemakersTableName).create(w => {
            w.name = winemaker.name;
            w.description = winemaker.description;
        })
        return newWinemaker;
    });
}
