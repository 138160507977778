import React, { Fragment, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { useCreateBlend } from './hooks';
import { useTranslation } from 'react-i18next';
import { useWinemaker } from '../seasons/seasons-context';
import { useBlendsPeriod } from '../blends-period/blends-period-context';

export function BlendsCreateModal({ open, onClose }) {

    const { t } = useTranslation(['translation', 'blends']);
    const createBlend = useCreateBlend();
    const winemaker = useWinemaker();
    const blendsPeriod = useBlendsPeriod();

    return (
        <Dialog open={open} onClose={onClose}>
            <Formik
                initialValues={{
                    name: '',
                    description: '',
                    volume: 100,
                    winemaker,
                    blendsPeriod
                }}
                onSubmit={async (blend, { setSubmitting }) => {
                    await createBlend(blend);
                    setSubmitting(false);
                    onClose();
                }}
            >
                {props => (
                    <Fragment>
                        <DialogTitle>
                            {t('Create a new Blend', { ns: 'blends' })}
                        </DialogTitle>
                        <DialogContent>
                            <Grid container spacing={2} sx={{ p: 2 }}>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        component={TextField}
                                        label={t('Name', { ns: 'blends' })}
                                        name="name"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        component={TextField}
                                        label={t('Description', { ns: 'blends' })}
                                        name="description"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        fullWidth
                                        component={TextField}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">hL</InputAdornment>
                                        }}
                                        inputProps={{
                                            min: 0,
                                        }}
                                        label={t('Volume', { ns: 'blends' })}
                                        name="volume"
                                        type="number"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button color='inherit' onClick={onClose}>
                                {t('Cancel')}
                            </Button>
                            <Button color='primary' onClick={props.submitForm}>
                                {t('Add')}
                            </Button>
                        </DialogActions>
                    </Fragment>
                )}
            </Formik>
        </Dialog >
    )
}

export default function BlendsCreateButton() {

    const [open, setOpen] = useState(false);

    const handleClickOpen = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        setOpen(true);
    };

    const handleClose = (ev) => {
        if (ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }

        setOpen(false);
    };

    return (
        <Box sx={{ p: 2 }}>
            <IconButton onClick={handleClickOpen}>
                <AddIcon />
            </IconButton>
            <BlendsCreateModal open={open} onClose={handleClose} />
        </Box>
    )
}
