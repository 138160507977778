import React from 'react';
import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSeason } from './seasons-context';
import SeasonTimeline from './season-timeline';

export default function SeasonDashboard() {

    const { t } = useTranslation('seasons');
    const season = useSeason();

    return (
        <Stack flexGrow={1} spacing={2}>
            <Typography variant='h4'>{t('Season:')} {season.name}</Typography>
            <SeasonTimeline />
        </Stack>
    )
}
