import React from 'react';
import { Box, CircularProgress, Grid, Stack, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { withBlend } from '../components/blends/observables';
import TanksInBlendsList from '../components/blends/tanks-used-in-blends-list';
import BlendCard from '../components/blends/blend-card';
import { useTranslation } from 'react-i18next';

function BlendAssemblyLoader() {
    return (
        <Box display='flex' alignItems='center' justifyContent='center'>
            <CircularProgress />
        </Box>
    )
}

function BlendsAssemblyInternal({ blend }) {

    const { t } = useTranslation("blends");

    if (!blend) {
        return <BlendAssemblyLoader />
    }

    return (
        <Grid display='block' container spacing={4} >
            <Grid item xs={12}>
                <BlendCard blend={blend} hideViewAction hideRemoveAction enableEditors />
            </Grid>

            <Grid item xs={12}>
                <Stack direction='column' alignItems='flex-start' spacing={2}>
                    <Typography variant='h4'>{t('Tanks in the blend')}</Typography>
                    <TanksInBlendsList blend={blend} />
                </Stack>
            </Grid>
        </Grid >
    );
}

const ConnectedBlendsAssemblyInternal = withBlend(BlendsAssemblyInternal);

function BlendsAssembly() {

    const { blend_id } = useParams();

    return (
        <ConnectedBlendsAssemblyInternal blend_id={blend_id} />
    );
}

export default BlendsAssembly;
