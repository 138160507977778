import { useDatabase } from '../../models/underground/database';
import { useCallback } from 'react';
import { createHarvestPeriod, endHarvestPeriod } from '../../models/HarvestPeriod';

export const useCreateHarvestPeriod = () => {
    const database = useDatabase();
    return useCallback(async (season) => {
        return await createHarvestPeriod(database, season);
    }, [database]);
}

export const useEndHarvestPeriod = () => {
    const database = useDatabase();
    return useCallback(async (harvestPeriod) => {
        return await endHarvestPeriod(database, harvestPeriod);
    }, [database]);
}
